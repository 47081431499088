import { FunctionComponent, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./HowItWorksSection.module.scss";
import BackgroundSectionTemplate, {
  BackgroundSectionTemplateColorType,
} from "../sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate";
import Grid from "../../blocks/Grid/Grid";

type HowItWorksBlocksContentType = {
  title: string;
  text: string | React.ReactElement;
  icon?: React.ReactElement;
};

interface HowItWorksSectionProps {
  sectionBackgroundColor?: BackgroundSectionTemplateColorType;
  title: string;
  textUnderTitle?: string;
  items: HowItWorksBlocksContentType[];
}

const HowItWorksSection: FunctionComponent<HowItWorksSectionProps> = ({
  sectionBackgroundColor = "white",
  title,
  textUnderTitle,
  items,
}) => {
  const numberOfFirstItems = 2;

  const firstItems = items.slice(0, numberOfFirstItems);
  const lastItems = items.slice(numberOfFirstItems);

  return (
    <section className={styles["section"]}>
      <BackgroundSectionTemplate
        color={sectionBackgroundColor}
      ></BackgroundSectionTemplate>

      <div className="wrapper">
        <div className="content">
          <div className={styles["title"]}>
            <h2 className="text text--h2">{title}</h2>
          </div>

          {textUnderTitle && (
            <div className={styles["text-under-title"]}>
              <p className="text text--body-1">{textUnderTitle}</p>
            </div>
          )}

          <div className={styles["block-list-outer"]}>
            <div
              className={classNames(
                styles["block-list"],
                styles["block-list-1"]
              )}
            >
              {firstItems.map((item, i) => {
                return (
                  <div className={styles["block-list__item"]} key={i}>
                    <div className={styles["block-list__item__line"]}></div>

                    <div className={styles["block-list__item__inner"]}>
                      <div className={styles["block-list__item__title"]}>
                        <p className="text text--h4">{item.title}</p>
                      </div>

                      <div className={styles["block-list__item__bottom-part"]}>
                        {item.icon && (
                          <div className={styles["block-list__item__icon"]}>
                            {item.icon}
                          </div>
                        )}

                        <div className={styles["block-list__item__text"]}>
                          <p className="text text--body-2">{item.text}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={classNames(
                styles["block-list"],
                styles["block-list-2"]
              )}
            >
              <div className={styles["block-list__line"]}></div>
              <Grid
                itemGroup={lastItems.map((item, i) => {
                  return (
                    <div className={styles["block-list__item"]} key={i}>
                      <div className={styles["block-list__item__line"]}></div>

                      <div className={styles["block-list__item__inner"]}>
                        <div className={styles["block-list__item__title"]}>
                          <p className="text text--h4">{item.title}</p>
                        </div>

                        <div
                          className={styles["block-list__item__bottom-part"]}
                        >
                          {item.icon && (
                            <div className={styles["block-list__item__icon"]}>
                              {item.icon}
                            </div>
                          )}

                          <div className={styles["block-list__item__text"]}>
                            <p className="text text--body-2">{item.text}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
