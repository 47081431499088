import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './NavigationLink.module.scss';

export type SizeType = 'default' | 'smaller' | 'small' | 'small-2' | 'large';
export type ColorType = 'default' | 'blue' | 'dark' | 'white';

export interface NavigationLinkProps extends PropsWithChildren {
  path: string;
  className?: string;
  sizeType?: SizeType;
  colorType?: ColorType;
  withBorder?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  disabled?: boolean;
  onClick?(Event): void;
  target?: string;
  rel?: string;
}

const NavigationLink: FunctionComponent<NavigationLinkProps> = ({
  path,
  className,
  sizeType,
  colorType,
  withBorder = true,
  iconLeft,
  iconRight,
  disabled = false,
  children,
  onClick,
  ...rest
}) => {
  return (
    <NavLink
      className={classNames(
        styles['nav-link'],
        styles[sizeMap[sizeType ?? 'default']],
        styles[colorMap[colorType ?? 'default']],
        withBorder === true && styles['nav-link--with-border'],
        disabled && styles['nav-link--disabled'],
        className
      )}
      to={path}
      onClick={onClick}
      {...rest}
    >
      {iconLeft && <div className={classNames(styles['nav-link__icon'])}>{iconLeft}</div>}

      <div className={classNames(styles['nav-link__text'])}>{children}</div>

      {iconRight && <div className={classNames(styles['nav-link__icon'])}>{iconRight}</div>}
    </NavLink>
  );
};

export const sizeMap: Record<SizeType, string> = {
  default: 'nav-link--default',
  smaller: 'nav-link--smaller',
  small: 'nav-link--small',
  'small-2': 'nav-link--small-2',
  large: 'nav-link--large',
};

export const colorMap: Record<ColorType, string> = {
  default: 'nav-link--color-default',
  blue: 'nav-link--color-blue',
  dark: 'nav-link--color-dark',
  white: 'nav-link--color-white',
};

export default NavigationLink;
