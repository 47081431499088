enum RoutesEnum {
  HOME = '/',
  CONNECT = 'connect',
  CONSTELLATION_DINNERS = 'founder-dinners',
  SUPERNOVA_DINNERS = 'investor-dinners',
  GALAXY_DINNERS = 'investor-club',
  AURORA_DINNERS = 'biz-dev-dinners',
  SLACK_COMMUNITY = 'slack-community',
  BUILD = 'build',
  LEARN = 'learn',
  FUND = 'fund',
  GROW = 'grow',
  PRICING = 'pricing',
  PARTNER = 'partner',
  ABOUT = 'about',
  TERMS_OF_USE = 'terms-of-use',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  CREATE_PASSWORD = 'create-password',
  FORGOT_PASSWORD = 'forgot-password',
  PASSWORD_CHANGED = 'password-changed',
  CONFIRM_EMAIL = 'confirm-email',
  NAME_AND_ROLES = 'name-and-roles',
  COMPLETE_PROFILE = 'complete-profile',
  MANIFESTO = 'manifesto',
  ACCOUNT = 'account',
  BILLING = 'billing',
  ROCKET_FUEL = 'rocket-fuel',
  ACCOUNT_BOOST = 'boost',
  ROLE = 'role',
  PROFILE = 'profile',

  UI_UX_DESIGN = 'ui-ux-design',
  PITCH_DECK_DESIGN = 'pitch-deck-design',
  BRAND_IDENTITY_DESIGN = 'brand-identity-design',
  WEB_DEVELOPMENT = 'wep-development',
  MOBILE_DEVELOPMENT = 'mobile-development',
  API_INTEGRATIONS = 'api-integrations',
  TECH_STACK = 'tech-stack',
  DATA_ARCHITECTURE = 'data-architecture',
  DEV_SEC_OPS = 'dev-sec-ops',

  SERVICE_DETAIL = 'service-detail',
  CASE_STUDY = 'case-study',

  SCHEDULE_MEETING = 'schedule-meeting',

  WELCOME = 'welcome',

  NOT_FOUND = 'not-found',
}

export default RoutesEnum;
