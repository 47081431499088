import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import HeroSectionWithVerticalContent from '../../components/sections/HeroSectionWithVerticalContent/HeroSectionWithVerticalContent';

import { ReactComponent as HeroIll } from '../../asset/images/partner_ill.svg';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';

import styles from './PartnerView.module.scss';
import MainButton from '../../components/buttons/MainButton/MainButton';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';

interface PartnerViewProps extends IWithUserRoleProps {}

const PartnerView: FunctionComponent<PartnerViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplateForMainPages>
      <HeroSectionWithVerticalContent
        title={
          <>
            <mark>Partner</mark> with Astronomic <br />
            as a referrer or reseller⚡️
          </>
        }
        text="We wouldn’t be where we are today without the resellers and referrers who’ve helped us extend our business. We’re happy to offer solutions to both partner personas. Read on to find out how you can get involved."
        illustration={<HeroIll />}
        sectionBackgroundColor="blueGradient"
      />

      <SectionDivider color="gray" />

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title="Partner Opportunities ✨"
            text="Whether you send us customers, or you price on top of us, we’re happy to accommodate you."
            textAlign="left"
          />
        }
        backgroundColor="gray"
      >
        <div className={styles['partner-block-list']}>
          <div className={styles['item']}>
            <div className={styles['item__inner']}>
              <div className={styles['item__image']}>
                <img src={require('../../asset/images/partner_1.jpg')} alt="Partner" />
              </div>

              <div className={styles['item__info']}>
                <div className={styles['item__info__sub-title']}>
                  <p className="text text--caps-small">Referrers</p>
                </div>

                <div className={styles['item__info__title']}>
                  <h4 className="text text--h4">Refer customers for 5% of all revenue for 1 year</h4>
                </div>

                <div className={styles['item__info__text']}>
                  <p className="text text--body-2">
                    We pay 5% of all revenue referred to us by partners for the first 12 months of their work with us.
                    Join our Slack as a revenue-sharing partner.
                  </p>
                </div>

                <div className={styles['item__info__button']}>
                  {!isUserLoggedIn ? (
                    <MainButton
                      sizeType="large"
                      visualType="main"
                      onClick={() => {
                        router.navigate(`/${RoutesEnum.SIGN_UP}`);
                      }}
                    >
                      Apply as a partner
                    </MainButton>
                  ) : (
                    <MainButton
                      sizeType="large"
                      visualType="main"
                      onClick={() => {
                        router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
                      }}
                    >
                      Schedule a meeting
                    </MainButton>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={styles['item']}>
            <div className={styles['item__inner']}>
              <div className={styles['item__image']}>
                <img src={require('../../asset/images/partner_2.jpg')} alt="Partner" />
              </div>

              <div className={styles['item__info']}>
                <div className={styles['item__info__sub-title']}>
                  <p className="text text--caps-small">Resellers</p>
                </div>

                <div className={styles['item__info__title']}>
                  <h4 className="text text--h4">White-label and price on top of our offerings</h4>
                </div>

                <div className={styles['item__info__text']}>
                  <p className="text text--body-2">
                    Join other founders and investors for business leadership discussion, Q&A, and resource sharing.
                    This community is moderated by our team.
                  </p>
                </div>

                <div className={styles['item__info__button']}>
                  {!isUserLoggedIn ? (
                    <MainButton
                      sizeType="large"
                      visualType="main"
                      onClick={() => {
                        router.navigate(`/${RoutesEnum.SIGN_UP}`);
                      }}
                    >
                      Apply as a partner
                    </MainButton>
                  ) : (
                    <MainButton
                      sizeType="large"
                      visualType="main"
                      onClick={() => {
                        router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
                      }}
                    >
                      Schedule a meeting
                    </MainButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChildrenWithHeaderSection>

      {/* <SectionDivider color="white" />

      <TestimonialsSection /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(PartnerView);
