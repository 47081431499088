import { FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../../../../asset/images/astronomic_logo.svg';
import { ReactComponent as ExitIcon } from '../../../../../asset/images/icons/exit.svg';
import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { UserRoleEnum } from '../../../../../enums/user';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import RoleSelector from '../../../../../components/inputs/RoleSelector/RoleSelector';
import './Header.css';
import RocketFuel from '../../../../../components/blocks/RocketFuel/RocketFuel';
import OnlyIconButton from '../../../../../components/buttons/OnlyIconButton/OnlyIconButton';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { logout } from '../../../../../utils/auth/logout';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import BurgerButton from '../../../../../components/buttons/BurgerButton/BurgerButton';
import MobileMenu from './_parts/MobileMenu';
import RoutesEnum from '../../../../../enums/routes';

type HeaderColorType = 'blue' | 'white';

const HeaderColorMap: Record<HeaderColorType, string> = {
  blue: '',
  white: 'header--color-white',
};

interface HeaderProps {
  headerColor?: HeaderColorType;
  hideRoleSelector?: boolean;
  user_role: UserRoleEnum;
  loggedIn?: boolean;
  [x: string]: any;
}

const Header: FunctionComponent<HeaderProps> = ({
  headerColor = 'white',
  user_role,
  hideRoleSelector = false,
  loggedIn = false,
  ...rest
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const userFirstName = useAppSelector((state) => state.user.user?.firstName);
  const rocketFuelBalance = useAppSelector((state) => state.user.user?.rocketFuelBalance as number);

  const isTablet = useMediaQuery('(max-width: 1279px)');
  const isMobile = useMediaQuery('(max-width: 767px)');

  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);

  const handleOnExit = () => logout(dispatch);

  const handleToggleMobileMenu = () => {
    setMobileMenuActive((state) => !state);
  };

  useEffect(() => {
    setMobileMenuActive(false);
  }, [pathname]);

  return (
    <>
      <header
        className={classNames(
          'header',
          HeaderColorMap[headerColor],
          'global__header',
          mobileMenuActive && 'header--fixed'
        )}
        {...rest}
      >
        <div className="background"></div>
        <div className="wrapper type-1">
          <div className="block-list flex middle space-between">
            <div className="block-item block-item-1 flex middle">
              {isTablet && (
                <BurgerButton
                  className="el-burger"
                  active={mobileMenuActive}
                  burgerColor={headerColor === 'blue' ? 'light' : 'dark'}
                  onClick={handleToggleMobileMenu}
                />
              )}

              <NavLink className="el-logo" to={`/${user_role}`}>
                <Logo />
              </NavLink>

              {!isTablet && (
                <nav className="el-menu-nav">
                  <ul className="el-menu flex">
                    <li>
                      <NavLink to={`/${user_role}/${RoutesEnum.CONNECT}`}>Connect</NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${user_role}/${RoutesEnum.BUILD}`}>Build</NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${user_role}/${RoutesEnum.LEARN}`}>Learn</NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${user_role}/${RoutesEnum.FUND}`}>Fund</NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${user_role}/${RoutesEnum.GROW}`}>Grow</NavLink>
                    </li>
                  </ul>
                </nav>
              )}
            </div>

            <div className="block-item block-item-2 flex middle">
              {!isMobile && (
                <>
                  {hideRoleSelector === false && (
                    <>
                      {mobileMenuActive === false && (
                        <div className="el-role-selector">
                          <RoleSelector user_role={user_role} color={headerColor} />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              {loggedIn === false ? (
                <>
                  {mobileMenuActive === false && (
                    <div className="el-button-group-list flex middle">
                      <div className="el-button-group-item">
                        <MainButton
                          sizeType="medium"
                          visualType="main"
                          onClick={() => {
                            navigate(`/${RoutesEnum.SIGN_UP}`);
                          }}
                        >
                          Join for free
                        </MainButton>
                      </div>

                      {!isMobile && (
                        <div className="el-button-group-item">
                          <MainButton
                            sizeType="medium"
                            visualType={headerColor === 'white' ? 'white' : 'blank'}
                            onClick={() => {
                              navigate(`/${RoutesEnum.SIGN_IN}`);
                            }}
                          >
                            Sign in
                          </MainButton>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {mobileMenuActive === false && (
                    <div className="el-logged-in-button-group-list flex middle">
                      {isMobile === false && (
                        <RocketFuel
                          number={rocketFuelBalance}
                          color={headerColor === 'white' ? 'default' : 'blank'}
                          link={`/${RoutesEnum.ACCOUNT}/${RoutesEnum.ROCKET_FUEL}`}
                        />
                      )}

                      <NavLink to={`/${RoutesEnum.ACCOUNT}`} className="el-logged-in-button-group-avatar-container">
                        <img
                          className="el-logged-in-button-group-avatar-container-image"
                          src={require('../../../../../asset/images/avatar.png')}
                          alt="avatar"
                        />

                        <p className="el-logged-in-button-group-avatar-container-name text text--body-2 text--bold">
                          {userFirstName}
                        </p>
                      </NavLink>

                      {isMobile === false && (
                        <OnlyIconButton
                          className="el-logged-in-button-group-exit-button"
                          icon={<ExitIcon />}
                          visualType={headerColor === 'white' ? 'white' : 'blank'}
                          onClick={handleOnExit}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      {mobileMenuActive && (
        <>
          {isTablet && (
            <MobileMenu
              userRole={user_role}
              roleSelectorColor={'blue'}
              hideRoleSelector={hideRoleSelector}
              onExit={handleOnExit}
            />
          )}
        </>
      )}
    </>
  );
};

export default Header;
