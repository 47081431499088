import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as ConnectIll } from '../../asset/images/connect_illustration.svg';
import MainButton from '../../components/buttons/MainButton/MainButton';
import CardsWithHeaderSection from '../../components/sections/CardsWithHeaderSection/CardsWithHeaderSection';
import Grid from '../../components/blocks/Grid/Grid';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import { ReactComponent as ThumbIcon } from '../../asset/images/icons/thumbs_up.svg';
import { ReactComponent as StairUpIcon } from '../../asset/images/icons/stair_up.svg';
import { ReactComponent as CaseIcon } from '../../asset/images/icons/case.svg';
import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import RoutesEnum from '../../enums/routes';
import productsData from '../../data/products';
import { useAppSelector } from '../../redux/hooks';
import connectPageContent from '../../data/connect';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';

interface ConnectViewProps extends IWithUserRoleProps {}

const ConnectView: FunctionComponent<ConnectViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplateForMainPages>
      <HeroSection
        secondaryTitle="Connect"
        title="Meet relevant founders and investors"
        text={connectPageContent[user_role]}
        sectionVariation="var-1"
        illustration={<ConnectIll style={{ maxHeight: '512px' }} />}
      />

      <SectionDivider color="gray" />

      <CardsWithHeaderSection
        title="Connect"
        text="Join other founders and investors for business leadership discussion, Q&A, and resource sharing. This community is moderated by our team."
        sectionBackgroundColor="gray"
        sectionVariation="var-1"
        button={
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              Join Astronomic for free
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              Schedule a meeting
            </MainButton>
          )
        }
      >
        <Grid
          itemGroup={Object.entries(productsData.connect).map(([key, value], i) => {
            return (
              <ServiceCard
                title={value.title}
                text={value.shortDescription}
                price={value.price!}
                link={`/${user_role}/${value.link}`}
                disabled={!value.link && true}
              />
            );
          })}
          itemsInRow={3}
        />
      </CardsWithHeaderSection>

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle secTitle="Collaborate to succeed" title="Better together" />}
        backgroundColor="gray"
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Better together"
              text="It's lonely at the top, but not here. Share your challenges, goals, and questions with other founders, learn from their experiences, and develop rewarding friendships in the process."
              icon={<ThumbIcon style={{ maxWidth: '29px' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Accountability"
              text="It’s one thing to set your own goals, but it’s another thing to verbalize them to your peers. Set growth goals and let fellow founders hold you accountable to achieve them."
              icon={<StairUpIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Skills"
              text="Gain valuable education and level-up your business skills, thanks to the insights shared by fellow founders who’ve been there/done that."
              icon={<CaseIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Growth"
              text="Track your success over time, build valuable connections, and take your business to the next level thanks to the power of Astronomic Network."
              icon={<RocketIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      {/* <SectionDivider color="white" />

      <TestimonialsSection /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(ConnectView);
