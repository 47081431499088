import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as AboutIll } from '../../asset/images/about_ill.svg';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import MainButton from '../../components/buttons/MainButton/MainButton';

import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket_font.svg';
import { ReactComponent as AstronomerIcon } from '../../asset/images/icons/astronomer_font.svg';
import { ReactComponent as PlanetIcon } from '../../asset/images/icons/planet_font.svg';

import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import Grid from '../../components/blocks/Grid/Grid';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import ValuesSection from '../../components/sections/ValuesSection/ValuesSection';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import { GENERAL_CONTENT } from '../../data/generalContent';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';

interface AboutViewProps extends IWithUserRoleProps {}

const AboutView: FunctionComponent<AboutViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplateForMainPages footerSectionVariation="var-1">
      <HeroSection
        secondaryTitle={<mark>About us</mark>}
        title="No one goes to the moon alone"
        text={
          <>
            <p>
              <mark>Entrepreneurship is lonesome</mark>. At Astronomic, we’re passionate about building social
              infrastructure to connect innovators across the globe.
            </p>

            <p>
              By making relevant introductions between founders, builders, mentors, investors, and customers, we feel
              most fulfilled driving collaboration that enables everyone’s collective success.
            </p>
          </>
        }
        sectionVariation="var-1-c-3"
        illustration={<AboutIll style={{ marginRight: 'auto', marginLeft: 'auto', maxHeight: '512px' }} />}
        buttonGroup={[
          !isUserLoggedIn ? (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
            </MainButton>
          ) : (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              {GENERAL_CONTENT.SCHEDULE_A_MEETING}
            </MainButton>
          ),
          <MainButton
            visualType="blank"
            sizeType="large"
            onClick={() => {
              router.navigate(`/${user_role}/${RoutesEnum.PRICING}`);
            }}
          >
            How Astronomic works
          </MainButton>,
        ]}
        markTagColor="blue"
      />

      <SectionDivider color="white" />

      <ChildrenWithHeaderSection backgroundColor="white">
        <Grid
          itemsInRow={3}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Vision"
              text="To innovate our way into the cosmos."
              icon={<RocketIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
            <TitleWithIconAndTextCard
              title="Mission"
              text="To enable as much entrepreneurship as possible"
              icon={<AstronomerIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
            <TitleWithIconAndTextCard
              title="Purpose"
              text="To connect the world, using entrepreneurship as a conduit for social change."
              icon={<PlanetIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title="Diversity, Equity, Inclusion, and Belonging (DEIB)"
            titleSize="h3"
            text={
              <>
                At Astronomic, we’re building an <mark>open</mark>, <mark>collaborative</mark>, and{' '}
                <mark>inclusive</mark> culture that values each person’s unique contributions in the collective
                foundation of our company, community, and customers.
              </>
            }
          />
        }
      ></ChildrenWithHeaderSection>

      <SectionDivider color="blue" />

      <HeroSection
        title={
          <>
            Built with 🚀 in <br />
            Austin, TX
          </>
        }
        titleSize="h2"
        text="Astronomic was founded in 2020 by Chris Beaman, who previously founded and sold two SaaS companies. After participating in the Techstars accelerator program, Chris was eager to try building a tech-enabled “forever accelerator.” With a background in software development and a passion for community organizing, Chris set out to build Astronomic and enable anyone with a fire in their belly to build their own company."
        sectionVariation="var-1-c-2"
        wrapperClass={null}
        imageUrl={require('../../asset/images/about_photo.jpg')}
      />

      {/* <SectionDivider color="gray" />

      <ValuesSection
        sectionVariation="var-1"
        itemsInRow={5}
        title="Our Values"
        text="In our orbit, entrepreneurs are “astronomers” who uphold the following values."
        itemsData={[
          {
            title: 'Humility',
            text: 'We’re open to critical feedback',
          },
          {
            title: 'Courage',
            text: 'We have the strength to persevere',
          },
          {
            title: 'Integrity',
            text: 'We’re open, honest, and forthright',
          },
          {
            title: 'Ambition',
            text: 'We dream big and work hard',
          },
          {
            title: 'Diversity',
            text: 'We embrace all people and ideas',
          },
        ]}
      /> */}

      {/* <SectionDivider color="gray" /> */}

      {/* <TestimonialsSection backgroundColor="gray" /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(AboutView);
