import { FunctionComponent, useEffect, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import MainButton from '../../components/buttons/MainButton/MainButton';
import Grid from '../../components/blocks/Grid/Grid';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';
import TextBillet from '../../components/blocks/TextBillet/TextBillet';
import NavigationLink from '../../components/links/NavigationLink/NavigationLink';
import TitleWithElem from '../../components/titles/TitleWithElem/TitleWithElem';
import CaseStudyCard from '../../components/blocks/CaseStudyCard/CaseStudyCard';
import { ReactComponent as CandidlyLogo } from '../../asset/images/candidly_logo.svg';
import { ReactComponent as ButtonArrow } from '../../asset/images/button_arrow.svg';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import styles from './ServiceDetailView.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../dto/api';
import PreloadProductCard from '../../components/blocks/PreloadProductCard/PreloadProductCard';
import { sessionStorageGetItem, sessionStorageSetItem } from '../../utils/sessionStorageMethods';
import { SESSION_STORAGE_KEYS } from '../../enums/sessionStorageKeys';
import { stringToUserFriendlyURL, truncateString } from '../../utils/stringFormatMethods';
import ComingSoonMessage from '../../components/texts/ComingSoonMessage/ComingSoonMessage';
import PreloadLine from '../../components/blocks/PreloadLine/PreloadLine';
import NotFoundView from '../NotFoundView/NotFoundView';

interface ServiceDetailViewProps extends IWithUserRoleProps {}

const ServiceDetailView: FunctionComponent<ServiceDetailViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const location = useLocation();
  const params = useParams();
  const serviceTitle = params.serviceTitle;

  const [services, setServices] = useState<Array<any>>();
  const [loading, setLoading] = useState(true);

  const serviceDetail = services?.find((item) => stringToUserFriendlyURL(item.name) === serviceTitle);
  const title = serviceDetail?.name;
  const description = serviceDetail?.description;

  const navigate = useNavigate();

  useEffect(() => {
    let cacheData = JSON.parse(sessionStorageGetItem(SESSION_STORAGE_KEYS.CACHE_SERVICES)!);

    const getServices = async () => {
      try {
        const response = await api.Content.getServicesByCategoryAndStage({
          growthStageId: null,
          serviceCategoryId: null,
        });
        const responseData = response.data;

        if (responseData.success === true && responseData.data) {
          setServices(responseData.data);
          sessionStorageSetItem(SESSION_STORAGE_KEYS.CACHE_SERVICES, JSON.stringify(responseData.data));
        } else {
          console.error('Error:', responseData.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;
        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }
        console.error('Error: ', error);
      } finally {
        setLoading(false);
      }
    };

    if (cacheData) {
      setServices(cacheData);
      setLoading(false);
    } else {
      getServices();
    }
  }, []);

  if (loading) {
    return (
      <MainTemplate color="white">
        <div style={{ height: '100vh' }}></div>
      </MainTemplate>
    );
  }

  if (!serviceDetail) {
    return <NotFoundView />;
  }

  return (
    <MainTemplate color="white">
      <HeroSection
        title={title ? title : ''}
        text={description ? description : ''}
        sectionVariation="var-1-b"
        buttonGroup={
          !isUserLoggedIn
            ? [
                <MainButton
                  sizeType={'large'}
                  visualType="main"
                  onClick={() => {
                    router.navigate(`/${RoutesEnum.SIGN_UP}`);
                  }}
                >
                  Join Astronomic for free
                </MainButton>,
              ]
            : null
        }
        breadcrumbs={<Breadcrumbs userRole={user_role} currentLinkText={title ? title : ' '} />}
        customElement={
          !isUserLoggedIn ? (
            <TextBillet linkText="How hourly billing works" linkUrl={`/${user_role}/${RoutesEnum.PRICING}`}>
              <NavigationLink path={`/${RoutesEnum.SIGN_IN}`} sizeType="small">
                Sign in
              </NavigationLink>{' '}
              or{' '}
              <NavigationLink path={`/${RoutesEnum.SIGN_UP}`} sizeType="small">
                create an account
              </NavigationLink>{' '}
              to schedule a free consultation. We’ll introduce you to one of our UI/UX Designers to estimate your
              project.
            </TextBillet>
          ) : (
            <div className={styles['logged-block']}>
              <MainButton
                sizeType={'large'}
                visualType="main"
                style={{ width: '300px', maxWidth: '100%' }}
                className={styles['logged-block__button']}
                onClick={() => {
                  router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
                }}
              >
                Schedule a meeting
              </MainButton>

              <NavigationLink
                className={styles['logged-block__link']}
                path={`/${user_role}/${RoutesEnum.PRICING}`}
                sizeType="small"
              >
                How hourly billing works
              </NavigationLink>
            </div>
          )
        }
      />

      {/* <SectionDivider color="white" />

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle title="Case Studies" />}
        wrapperClass="type-1"
        style={{ overflow: 'hidden' }}
      >
        <Grid
          itemGroup={[
            <CaseStudyCard
              imageUrl={require('../../asset/images/case_study_image.jpg')}
              title="Candidly photographer booking platform"
              text="Consectetur turpis erat in sapien nullam. Congue hac netus ac ut purus dignissim quis imperdiet. Arcu tincidunt tellus volutpat turpis est. Pellentesque quam quam laoreet ultrices."
              logo={<CandidlyLogo />}
              price="$6,400"
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}/${RoutesEnum.CASE_STUDY}`}
            />,
            <CaseStudyCard
              imageUrl={require('../../asset/images/case_study_image.jpg')}
              title="Candidly photographer booking platform"
              text="Consectetur turpis erat in sapien nullam. Congue hac netus ac ut purus dignissim quis imperdiet. Arcu tincidunt tellus volutpat turpis est. Pellentesque quam quam laoreet ultrices."
              logo={<CandidlyLogo />}
              price="$6,400"
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}/${RoutesEnum.CASE_STUDY}`}
            />,
            <CaseStudyCard
              imageUrl={require('../../asset/images/case_study_image.jpg')}
              title="Candidly photographer booking platform"
              text="Consectetur turpis erat in sapien nullam. Congue hac netus ac ut purus dignissim quis imperdiet. Arcu tincidunt tellus volutpat turpis est. Pellentesque quam quam laoreet ultrices."
              logo={<CandidlyLogo />}
              price="$6,400"
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}/${RoutesEnum.CASE_STUDY}`}
            />,
          ]}
        />

        <ComingSoonMessage type="overlay" />
      </ChildrenWithHeaderSection> */}

      <SectionDivider color="gray" />

      <ChildrenWithHeaderSection
        title={
          <TitleWithElem
            marginBottom="medium"
            element={
              <NavigationLink
                path={`/${user_role}/${RoutesEnum.BUILD}`}
                iconRight={<ButtonArrow />}
                sizeType="small"
                withBorder={false}
              >
                View all
              </NavigationLink>
            }
          >
            <h4 className="text text--h4">Related products</h4>
          </TitleWithElem>
        }
        backgroundColor="gray"
      >
        {services && services?.length > 0 ? (
          <Grid
            itemGroup={services
              ?.sort(() => 0.5 - Math.random())
              .slice(0, 3)
              .map((item, i) => {
                return (
                  <ServiceCard
                    title={item.name}
                    text={truncateString(item.description, 100)}
                    price={null}
                    link={`/${user_role}/${RoutesEnum.BUILD}/${stringToUserFriendlyURL(item.name)}`}
                    key={item.id}
                  />
                );
              })}
          />
        ) : (
          <Grid
            itemGroup={[1, 2, 3].map((item, i) => (
              <PreloadProductCard key={i} />
            ))}
          />
        )}
      </ChildrenWithHeaderSection>
    </MainTemplate>
  );
};

export default with_user_role(ServiceDetailView);
