import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './ServiceCard.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import { To, useNavigate } from 'react-router-dom';

export interface ServiceCardProps {
  title: string | React.ReactElement;
  text: string | React.ReactElement;
  price: 'Free' | string | null;
  textBeforePrice?: string;
  buttonText?: string;
  link?: To;
  size?: 'original' | 'small';
  customFooter?: React.ReactElement;
  disabled?: boolean;
  className?: string;
}

const ServiceCard: FunctionComponent<ServiceCardProps> = ({
  title,
  text,
  price,
  textBeforePrice = 'From',
  buttonText = 'See details',
  link,
  size = 'original',
  customFooter,
  disabled = false,
  className,
}) => {
  const navigate = useNavigate();

  return (
    <article
      className={classNames(
        styles['service-card'],
        'button-container-for-hover',
        styles[`service-card--size-${size}`],
        disabled && styles[`service-card--disabled`],
        className
      )}
      onClick={() => link && navigate(link)}
    >
      <div className={styles['top-part']}>
        <div className={styles['title']}>
          {typeof title === 'string' ? <h4 className="text text--h4">{title}</h4> : title}
        </div>

        <div className={styles['text']}>
          <p className="text text--body-2">{text}</p>
        </div>
      </div>

      <div className={classNames('bottom-part')}>
        {price === null || (
          <div className={styles['price']}>
            {price === 'Free' ? (
              <p className={classNames(styles['price__text-free'], 'text text--body-2 text--bold')}>Free</p>
            ) : (
              <p className="text text--body-2">
                {textBeforePrice && (
                  <span className={classNames(styles['price__text-before-price'], 'text text--caption')}>
                    {textBeforePrice}{' '}
                  </span>
                )}

                <span className={classNames(styles['service-card__text-price'], 'text text--body-2 text--bold')}>
                  {price}
                </span>
              </p>
            )}
          </div>
        )}

        {!customFooter && link && (
          <div className={styles['button']}>
            <MainButton sizeType="medium" visualType="white">
              {buttonText}
            </MainButton>
          </div>
        )}

        {customFooter && customFooter}
      </div>
    </article>
  );
};

export default ServiceCard;
