export enum Tags {
  PERSONAL_DETAILS = 'personal_details',
  CURRENT_FOUNDED_COMPANY = 'current_founded_company',
  CURRENT_OWNED_COMPANY = 'current_owned_company',
  MOST_RELEVANT_BUSINESS_TYPE = 'most_relevant_business_type',
  MOST_RELEVANT_INDUSTRY = 'most_relevant_industry',
  ANNUAL_REVENUE = 'annual_revenue',
  FUNDING_STAGE = 'funding_stage',
  GROWTH_GOALS = 'growth_goals',
  
  SPECIALTIES = 'specialties',

  SPECIALTIES_CONSULT = 'specialties_consult',

  DISCIPLINES_PRACTICED = 'disciplines_practiced',
  DISCIPLINES_CONSULTED = 'disciplines_consulted',

  INVESTOR_INVESTMENT_FOCUS = 'investor_frequency',
  INVESTOR_TYPE = 'investor_type',
  INVESTOR_ASSETS = 'investor_assets',
  INVESTOR_BUSINESS_TYPE = 'investor_business_type',
  INVESTOR_INDUSTRIES = 'investor_industries',
  INVESTOR_REVENUE_STAGE = 'investor_revenue_stage',
  INVESTOR_GROWTH_STAGE = 'investor_growth_stage',
  INVESTOR_GEOGRAPHIC_PREF = 'investor_geographic_pref',

  INCUBATOR_TEAM = 'incubator_team',
  INCUBATOR_BUSINESS_TYPE = 'incubator_business_type',
  INCUBATOR_INDUSTRIES = 'incubator_industries',
  INCUBATOR_REVENUE_STAGE = 'incubator_revenue_stage',
  INCUBATOR_GROWTH_STAGE = 'incubator_growth_stage',
  INCUBATOR_GEOGRAPHIC_PREF = 'incubator_geographic_pref',
  
  ACCELERATOR_TEAM = 'accelerator_team',
  ACCELERATOR_BUSINESS_TYPE = 'accelerator_business_type',
  ACCELERATOR_INDUSTRIES = 'accelerator_industries',
  ACCELERATOR_REVENUE_STAGE = 'accelerator_revenue_stage',
  ACCELERATOR_GROWTH_STAGE = 'accelerator_growth_stage',
  ACCELERATOR_GEOGRAPHIC_PREF = 'accelerator_geographic_pref',

  VENTURE_STUDIO_TEAM = 'venture_studio_team',
  VENTURE_STUDIO_BUSINESS_TYPE = 'venture_studio_business_type',
  VENTURE_STUDIO_INDUSTRIES = 'venture_studio_industries',
  VENTURE_STUDIO_REVENUE_STAGE = 'venture_studio_revenue_stage',
  VENTURE_STUDIO_GROWTH_STAGE = 'venture_studio_growth_stage',
  VENTURE_STUDIO_GEOGRAPHIC_PREF = 'venture_studio_geographic_pref',

  OFFICE_SPACE_BUSINESS_TYPE = 'office_space_business_type',
  OFFICE_SPACE_INDUSTRIES = 'office_space_industries',
  OFFICE_SPACE_REVENUE_STAGE = 'office_space_revenue_stage',
  OFFICE_SPACE_GROWTH_STAGE = 'office_space_growth_stage',

  EMPLOYEE_SENIORITY = 'employee_seniority',
  EMPLOYEE_FUNCTION = 'employee_function',
  EMPLOYEE_BUSINESS_TYPE = 'employee_business_type',
  EMPLOYEE_INDUSTRIES = 'employee_industries',
  EMPLOYEE_REVENUE_STAGE = 'employee_revenue_stage',
  EMPLOYEE_GROWTH_STAGE = 'employee_growth_stage',
  EMPLOYEE_COMPANY_GROWTH_GOALS = 'employee_company_growth_goals',
}

export const TagsRocketFuel: Record<Tags, number> = {
  [Tags.PERSONAL_DETAILS]: 5,
  [Tags.CURRENT_FOUNDED_COMPANY]: 5,
  [Tags.CURRENT_OWNED_COMPANY]: 5,
  [Tags.MOST_RELEVANT_BUSINESS_TYPE]: 5,
  [Tags.MOST_RELEVANT_INDUSTRY]: 5,
  [Tags.ANNUAL_REVENUE]: 5,
  [Tags.FUNDING_STAGE]: 5,
  [Tags.GROWTH_GOALS]: 5,

  [Tags.SPECIALTIES]: 5,
  [Tags.SPECIALTIES_CONSULT]: 5,
  [Tags.DISCIPLINES_PRACTICED]: 5,
  [Tags.DISCIPLINES_CONSULTED]: 5,

  [Tags.INVESTOR_INVESTMENT_FOCUS]: 5,
  [Tags.INVESTOR_TYPE]: 5,
  [Tags.INVESTOR_ASSETS]: 5,
  [Tags.INVESTOR_BUSINESS_TYPE]: 5,
  [Tags.INVESTOR_INDUSTRIES]: 5,
  [Tags.INVESTOR_REVENUE_STAGE]: 5,
  [Tags.INVESTOR_GROWTH_STAGE]: 5,
  [Tags.INVESTOR_GEOGRAPHIC_PREF]: 5,

  [Tags.INCUBATOR_TEAM]: 5,
  [Tags.INCUBATOR_BUSINESS_TYPE]: 5,
  [Tags.INCUBATOR_INDUSTRIES]: 5,
  [Tags.INCUBATOR_REVENUE_STAGE]: 5,
  [Tags.INCUBATOR_GROWTH_STAGE]: 5,
  [Tags.INCUBATOR_GEOGRAPHIC_PREF]: 5,

  [Tags.ACCELERATOR_TEAM]: 5,
  [Tags.ACCELERATOR_BUSINESS_TYPE]: 5,
  [Tags.ACCELERATOR_INDUSTRIES]: 5,
  [Tags.ACCELERATOR_REVENUE_STAGE]: 5,
  [Tags.ACCELERATOR_GROWTH_STAGE]: 5,
  [Tags.ACCELERATOR_GEOGRAPHIC_PREF]: 5,

  [Tags.VENTURE_STUDIO_TEAM]: 5,
  [Tags.VENTURE_STUDIO_BUSINESS_TYPE]: 5,
  [Tags.VENTURE_STUDIO_INDUSTRIES]: 5,
  [Tags.VENTURE_STUDIO_REVENUE_STAGE]: 5,
  [Tags.VENTURE_STUDIO_GROWTH_STAGE]: 5,
  [Tags.VENTURE_STUDIO_GEOGRAPHIC_PREF]: 5,

  [Tags.OFFICE_SPACE_BUSINESS_TYPE]: 5,
  [Tags.OFFICE_SPACE_INDUSTRIES]: 5,
  [Tags.OFFICE_SPACE_REVENUE_STAGE]: 5,
  [Tags.OFFICE_SPACE_GROWTH_STAGE]: 5,

  [Tags.EMPLOYEE_SENIORITY]: 5,
  [Tags.EMPLOYEE_FUNCTION]: 5,
  [Tags.EMPLOYEE_BUSINESS_TYPE]: 5,
  [Tags.EMPLOYEE_INDUSTRIES]: 5,
  [Tags.EMPLOYEE_REVENUE_STAGE]: 5,
  [Tags.EMPLOYEE_GROWTH_STAGE]: 5,
  [Tags.EMPLOYEE_COMPANY_GROWTH_GOALS]: 5,
}

export enum QuizComponents {
  PERSONAL_DETAILS = 'personal_details',
  CURRENT_FOUNDED_COMPANY = 'current_founded_company',
  CURRENT_OWNED_COMPANY = 'current_owned_company',
  INVESTOR_INVESTMENT_FOCUS = 'investor_investment_focus',
  CHECKBOX_LIST = 'checkbox_list',
  RADIO_LIST = 'radio_list'
}

export enum Roles {
  FOUNDER = 'founder',
  GOVERNMENT = 'government',
  BUSINESS_OWNER = 'business-owner',
  FREELANCE = 'freelance',
  CONSULT = 'consult',
  AGENCY = 'agency',
  INVESTOR = 'investor',
  INCUBATOR = 'incubator',
  ACCELERATOR = 'accelerator',
  VENTURE_STUDIO = 'venture-studio',
  OFFICE_SPACE = 'office-space',
  EMPLOYEE = 'employee'
}

export const RoleNames: Record<Roles, string> = {
  [Roles.FOUNDER]: 'I’m a current founder',
  [Roles.GOVERNMENT]: 'I represent government',
  [Roles.BUSINESS_OWNER]: 'I’m a business owner',
  [Roles.FREELANCE]: 'I freelance',
  [Roles.CONSULT]: 'I consult',
  [Roles.AGENCY]: 'I run an agency',
  [Roles.INVESTOR]: 'I’m an investor',
  [Roles.INCUBATOR]: 'I run an incubator',
  [Roles.ACCELERATOR]: 'I run an accelerator',
  [Roles.VENTURE_STUDIO]: 'I run a venture studio',
  [Roles.OFFICE_SPACE]: 'I run an office space',
  [Roles.EMPLOYEE]: 'I’m an employee',
};

export const RoleIds: Record<Roles, string> = {
  [Roles.FOUNDER]: '876A103F-6064-4256-FF17-08DBCA9B1610',
  [Roles.GOVERNMENT]: '31A4D9A0-985B-4401-FF23-08DBCA9B1610',
  [Roles.BUSINESS_OWNER]: 'D4248356-58D6-4673-FF19-08DBCA9B1610',
  [Roles.FREELANCE]: '4B06405F-215B-4E4D-FF1B-08DBCA9B1610',
  [Roles.CONSULT]: 'ACFCF800-7AE8-4D86-FF1C-08DBCA9B1610',
  [Roles.AGENCY]: 'D3621009-D734-49F1-FF1D-08DBCA9B1610',
  [Roles.INVESTOR]: 'C012D41A-0BC8-45AE-FF1A-08DBCA9B1610',
  [Roles.INCUBATOR]: 'C800D50F-BDD3-4D34-FF18-08DBCA9B1610',
  [Roles.ACCELERATOR]: '54355E4D-C51E-456A-FF1F-08DBCA9B1610',
  [Roles.VENTURE_STUDIO]: '3CE24498-6370-4608-FF20-08DBCA9B1610',
  [Roles.OFFICE_SPACE]: '2CD50074-4D56-4C3A-FF21-08DBCA9B1610',
  [Roles.EMPLOYEE]: 'F7369E43-A622-4C5F-FF22-08DBCA9B1610',
};

// RoleId	RoleName
// 876A103F-6064-4256-FF17-08DBCA9B1610	Founder
// D4248356-58D6-4673-FF19-08DBCA9B1610	Business Owner
// 4B06405F-215B-4E4D-FF1B-08DBCA9B1610	Freelancer
// ACFCF800-7AE8-4D86-FF1C-08DBCA9B1610	Consultant
// D3621009-D734-49F1-FF1D-08DBCA9B1610	Agency
// C012D41A-0BC8-45AE-FF1A-08DBCA9B1610	Investor
// C800D50F-BDD3-4D34-FF18-08DBCA9B1610	Incubator
// 12418F22-106E-479A-FF1E-08DBCA9B1610	Incubator (duplicate)
// 54355E4D-C51E-456A-FF1F-08DBCA9B1610	Accelerator
// 3CE24498-6370-4608-FF20-08DBCA9B1610	Venture Studio
// 2CD50074-4D56-4C3A-FF21-08DBCA9B1610	Co-working Space
// F7369E43-A622-4C5F-FF22-08DBCA9B1610	Employee
// 31A4D9A0-985B-4401-FF23-08DBCA9B1610	Government