import { FunctionComponent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as LayersIcon } from '../../../../../asset/images/icons/layers.svg';
import { ReactComponent as RocketIcon } from '../../../../../asset/images/icons/rocket.svg';
import { ReactComponent as AlertIcon } from '../../../../../asset/images/icons/alert.svg';
import styles from './AccountRocketFuel.module.scss';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import TitleWithIcon from '../../../../../components/titles/TitleWithIcon/TitleWithIcon';
import ProductCard from '../../../../../components/blocks/ProductCard/ProductCard';
import Grid from '../../../../../components/blocks/Grid/Grid';
import PopUp from '../../../../../components/popups/PopUp/PopUp';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import Countdown from '../../../../../components/blocks/Countdown/Countdown';
import Label from '../../../../../components/texts/Label/Label';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import api from '../../../../../api';
import { ApiResponseDTO } from '../../../../../dto/api';
import { AxiosError } from 'axios';
import { ProductDTO } from '../../../../../dto/product';
import ToastMessage from '../../../../../components/blocks/ToastMessage/ToastMessage';
import RoutesEnum from '../../../../../enums/routes';
import { toast } from 'react-toastify';
import ToastifyContent from '../../../../../components/blocks/ToastMessage/_parts/ToastifyContent/ToastifyContent';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import PreloadProductCard from '../../../../../components/blocks/PreloadProductCard/PreloadProductCard';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';
import useFormSubmitLoader from '../../../../../hooks/useFormSubmitLoader';
import { localStorageGetItem, localStorageSetItem } from '../../../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../../../enums/localStorageKeys';
import { SESSION_STORAGE_KEYS } from '../../../../../enums/sessionStorageKeys';
import { sessionStorageGetItem, sessionStorageSetItem } from '../../../../../utils/sessionStorageMethods';
import { getUserInfoAsyncThunk } from '../../../../../redux/slices/user/thunks';
import { get_env } from '../../../../../config/functions/app_functions';
import ShowOnlyInSomeEnv from '../../../../../components/blocks/ShowOnlyInSomeEnv/ShowOnlyInSomeEnv';

interface AccountRocketFuelProps {}

const AccountRocketFuel: FunctionComponent<AccountRocketFuelProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, handleFormSubmitLoader } = useFormSubmitLoader();

  const [productCardData, setProductCardData] = useState<ProductDTO[]>();
  const [creditsToBuy, setCreditsToBuy] = useState<ProductDTO>();

  const [buyCreditsPopUpActive, setSkipPopUpActive] = useState(false);
  const changeBuyCreditsPopUpActiveState = (state: boolean) => {
    setSkipPopUpActive(state);
  };

  const [accountIsBoosted, setAccountIsBoosted] = useState(false);

  const userId = useAppSelector((state) => state.user.user?.userId as string);
  const stripeCustomerId = useAppSelector((state) => state.user.user?.stripeCustomerId);
  const paymentMethodId = useAppSelector((state) => state.user.user?.stripeDefaultPaymentMethodId);
  const boostEndDate = useAppSelector((state) => state.user.user?.boostEndDate);

  async function handleOnChargeCustomerForProduct(stripeProductId: string) {
    await handleFormSubmitLoader(async () => {
      try {
        const response = await api.Payment.chargeCustomerForProduct({
          stripeCustomerId: stripeCustomerId!,
          stripeProductId: stripeProductId,
          paymentMethodId: paymentMethodId!,
          userId: userId,
        });

        const responseData = response.data;

        if (responseData.success === true) {
          changeBuyCreditsPopUpActiveState(false);

          if (userId) {
            await dispatch(getUserInfoAsyncThunk(userId));
          }

          callToast({
            title: 'Payment successful!',
            children: (
              <>
                Your account has just been topped up with <b>Rocket Fuel Credits</b> 🚀
              </>
            ),
            variation: 'success',
          });
        } else {
          toast.error((props) => {
            const variation = props.toastProps.type;

            return <ToastifyContent variation={variation}>{responseData?.errorMessage}</ToastifyContent>;
          });

          console.error('Error:', response);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        toast.error((props) => {
          const variation = props.toastProps.type;

          return <ToastifyContent variation={variation}>{errorData?.errorMessage}</ToastifyContent>;
        });

        console.error('Error: ', error);
      }
    });
  }

  function calculateCreditsWithBonus(price, discount) {
    const creditsWithBonus = price + (price * discount) / 100;

    return creditsWithBonus.toLocaleString('en-US');
  }

  function calculateBonusCredits(price, discount) {
    const bonusCredits = (price * discount) / 100;

    return bonusCredits.toLocaleString('en-US');
  }

  let response;

  useEffect(() => {
    let cacheData = JSON.parse(sessionStorageGetItem(SESSION_STORAGE_KEYS.CACHE_ROCKET_FUEL_PRODUCTS)!);

    const getStripeProducts = async () => {
      try {
        const response = await api.Payment.getFuelBundles(userId);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          const correctedData = responseData.data?.map((item, i) => {
            return {
              ...item,
              suggested: i === 1 ? true : false,
              discount: item.bonusPercentage,
            };
          });

          setProductCardData(correctedData);
          sessionStorageSetItem(SESSION_STORAGE_KEYS.CACHE_ROCKET_FUEL_PRODUCTS, JSON.stringify(correctedData));
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          alert(errorData.errorMessage);
        }

        console.error('Error: ', error);
      }
    };

    if (cacheData) {
      setProductCardData(cacheData);
    } else {
      getStripeProducts();
    }

    // NEED !!!!!! TO THINK HOW TO BYPASS RELOG AND CACHE WITH BOOSTED STATE
    // getStripeProducts();
  }, [response]);

  // THIS ONE IS FOR DEMO PURPOSE ONLY (DELETE AFTER PROPER IMPLEMENTATION)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (get_env() === 'development' || get_env() === 'staging')
        if (event.key === 'B' || event.key === 'b') {
          setAccountIsBoosted((prevValue) => !prevValue);
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    // test what if we try to get protected route with wrong or absent token
    const handleKeyDown2 = async (event) => {
      if (event.key === 'A' || event.key === 'a') {
        // await dispatch(getUserInfoAsyncThunk());
        try {
          const response = await api.Auth.getProtectedData();

          callToast({
            title: 'Payment successful!',
            children: (
              <>
                Your account has just been topped up with <b>Rocket Fuel Credits</b> 🚀
              </>
            ),
            variation: 'success',
          });

          console.log('This is "A" response: ', response);
        } catch (error) {
          console.log(error);
        }

        // alert('Protected endpoint was reached');
      }
    };

    window.addEventListener('keydown', handleKeyDown2);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keydown', handleKeyDown2);
    };
  }, []);
  // THIS ONE IS FOR DEMO PURPOSE ONLY (DELETE AFTER PROPER IMPLEMENTATION) END

  useEffect(() => {
    if (boostEndDate) {
      setAccountIsBoosted(true);
    }
  }, [boostEndDate]);

  return (
    <div className={styles['rocket-fuel']}>
      <div className={styles['account-boost']}>
        <div className={styles['account-boost__title-outer']}>
          <TitleWithIcon
            className={styles['account-boost__title']}
            icon={
              <LayersIcon
                className={styles['account-boost__title-container__icon']}
                style={{ fill: 'var(--color-main)', width: '29px' }}
              />
            }
          >
            <h3 className={'text text--h3'}>Account Boost</h3>
          </TitleWithIcon>

          {accountIsBoosted === true && (
            <Label className={styles['account-boost__title__label']} text="Active" visualStyle="small" />
          )}
        </div>

        {accountIsBoosted === false && (
          <>
            <p className={classNames(styles['account-boost__text'], 'text text--body-1')}>
              Boost your account and unlock a <b>33% discount</b> for one year, maximizing the value you receive. Also,
              earn <b>double the Rocket Fuel credits</b> from each purchase, giving you more power for your growth.
              <ShowOnlyInSomeEnv>
                <br />
                <span className="text text--caption">(Press "B" on keyboard to change boosted state)</span>
              </ShowOnlyInSomeEnv>
            </p>

            <MainButton
              className={styles['account-boost__button']}
              onClick={() => navigate('/account/rocket-fuel/boost')}
            >
              Learn more
            </MainButton>
          </>
        )}

        {accountIsBoosted === true && (
          <>
            <p className={classNames(styles['account-boost__text'], 'text text--body-1')}>
              <b>Your account is boosted</b>. You have up to <b>33% discount on all services</b> and{' '}
              <b>double rocket fuel credits</b> when subscribing to any plan.
              <ShowOnlyInSomeEnv>
                <br />
                <span className="text text--caption">(Press "B" on keyboard to change boosted state)</span>
              </ShowOnlyInSomeEnv>
            </p>

            <footer className={styles['account-boost__footer']}>
              <MainButton
                className={styles['account-boost__footer__button-2']}
                visualType="white"
                onClick={() => navigate('/account/rocket-fuel/boost')}
              >
                View details
              </MainButton>

              <Countdown
                className={styles['account-boost__footer__countdown']}
                colorStyle="gray"
                startDate={new Date()}
                endDate={boostEndDate ? new Date(boostEndDate as Date) : new Date()}
              />
            </footer>
          </>
        )}
      </div>

      <div className={styles['credits']}>
        <TitleWithIcon
          className={styles['credits__title']}
          icon={
            <RocketIcon
              className={styles['credits__title-container__icon']}
              style={{ fill: 'var(--color-main)', width: '24px' }}
            />
          }
        >
          <h3 className={'text text--h3'}>Rocket Fuel Credits</h3>
        </TitleWithIcon>

        <p className={classNames(styles['credits__text'], 'text text--body-1')}>
          Buy Rocket Fuel at a discount for later use. Credit purchases are a one-time charge and non-refundable.
        </p>

        <div className={styles['credits__grid']}>
          {productCardData ? (
            <Grid
              itemGroup={productCardData.map((item, i) => (
                <ProductCard
                  key={i}
                  title={`$${calculateCreditsWithBonus(item.amountInDollars, item.discount)} in credits`}
                  price={`$${item.amountInDollars.toLocaleString('en-US')} credits`}
                  bonusCredits={`$${item.bonusAmount} in bonus credits`}
                  buttonText={`${item.currency.toLocaleUpperCase()} $${item.amountInDollars.toLocaleString('en-US')}`}
                  discount={`${item.discount}% more`}
                  suggested={item.suggested}
                  onClick={() => {
                    changeBuyCreditsPopUpActiveState(true);
                    setCreditsToBuy(productCardData[i]);
                  }}
                />
              ))}
            />
          ) : (
            <Grid
              itemGroup={[1, 2, 3, 4, 5, 6].map((item, i) => (
                <PreloadProductCard key={i} />
              ))}
            />
          )}
        </div>
      </div>

      {createPortal(
        <PopUp
          className={styles['buy-credits-pop-up']}
          title="Buy Rocket Fuel credits"
          active={buyCreditsPopUpActive}
          onPopUpStateChange={changeBuyCreditsPopUpActiveState}
          footer={
            <div className={styles['buy-credits-pop-up__footer-button-list']}>
              <div className={styles['buy-credits-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="white"
                  onClick={() => {
                    changeBuyCreditsPopUpActiveState(false);
                  }}
                >
                  Cancel
                </MainButton>
              </div>

              {stripeCustomerId ? (
                <div className={styles['buy-credits-pop-up__footer-button-list__item']}>
                  <MainButton
                    visualType="main"
                    onClick={() => handleOnChargeCustomerForProduct(creditsToBuy?.productId!)}
                    disabled={isLoading}
                  >
                    Confirm purchase
                  </MainButton>
                </div>
              ) : (
                <div className={styles['buy-credits-pop-up__footer-button-list__item']}>
                  <MainButton
                    visualType="main"
                    onClick={() => navigate(`/${RoutesEnum.ACCOUNT}/${RoutesEnum.BILLING}`)}
                  >
                    Go to Billing
                  </MainButton>
                </div>
              )}
            </div>
          }
        >
          <CSSTransitionWrapper onEnter={isLoading} styleVariation="onForeground" className="">
            <AstronomicLoader variation="blurredBackground" />
          </CSSTransitionWrapper>

          <p
            className={classNames(styles['buy-credits-pop-up__text-1'], 'text text--h5')}
          >{`$${calculateCreditsWithBonus(creditsToBuy?.amountInDollars, creditsToBuy?.discount)} in credits`}</p>

          <div className={styles['buy-credits-pop-up__price']}>
            <p className="text text--caption">{`$${creditsToBuy?.amountInDollars.toLocaleString('en-US')} credits`}</p>
            <div className={styles['buy-credits-pop-up__price__dot']}></div>
            <p className="text text--caption">{`$${creditsToBuy?.bonusAmount} in bonus credits`}</p>
          </div>

          <p className={classNames(styles['buy-credits-pop-up__text-2'], 'text text--body-2 text--bold')}>
            {`Total: $${creditsToBuy?.amountInDollars.toLocaleString('en-US')}`}
          </p>

          <p className={classNames(styles['buy-credits-pop-up__text-3'], 'text text--body-2')}>
            Credit purchases are a one-time charge and non-refundable.
          </p>

          {!stripeCustomerId && (
            <ToastMessage
              className={styles['buy-credits-pop-up__alert']}
              visualStyle="1"
              customIcon={<AlertIcon />}
              title="No Credit Card"
            >
              <b>You don't have a credit card saved</b>. Before proceeding with the purchase, please go to Billing tab
              and register a credit card.
            </ToastMessage>
          )}
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </div>
  );
};

export default AccountRocketFuel;
