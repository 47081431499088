import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import MainButton from '../../components/buttons/MainButton/MainButton';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import { ReactComponent as PricingIll } from '../../asset/images/pricing_ill.svg';

import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';
import { ReactComponent as ShareIcon } from '../../asset/images/icons/share.svg';

import { ReactComponent as PeopleIcon } from '../../asset/images/icons/people_2.svg';
import { ReactComponent as ThumbsUpIcon } from '../../asset/images/icons/thumbs_up_2.svg';
import { ReactComponent as ThumbsUp2Icon } from '../../asset/images/icons/thumbs_up_3.svg';
import { ReactComponent as CaseIcon } from '../../asset/images/icons/case_2.svg';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import HowItWorksSection from '../../components/sections/HowItWorksSection/HowItWorksSection';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import Grid from '../../components/blocks/Grid/Grid';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import AccordionItem from '../../components/lists/AccordionItem/AccordionItem';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import { GENERAL_CONTENT } from '../../data/generalContent';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';

interface PricingViewProps extends IWithUserRoleProps {}

const PricingView: FunctionComponent<PricingViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplateForMainPages>
      <HeroSection
        secondaryTitle="Equity"
        title="Ready to become an astronomer?"
        text="We've helped hundreds of companies build their infrastructure, accelerate their growth, and drive value for their customers and communities.Simple and transparent pricing plans for your whole company. "
        sectionVariation="var-1"
        illustration={<PricingIll style={{ marginRight: 'auto', marginLeft: 'auto', maxHeight: '512px' }} />}
        buttonGroup={[
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              {GENERAL_CONTENT.SCHEDULE_A_MEETING}
            </MainButton>
          ),
        ]}
      />

      <SectionDivider color="white" />

      <HowItWorksSection
        title="How it works"
        textUnderTitle="Sit amet eu ullamcorper commodo mauris lectus dapibus mauris. Sem nunc elementum."
        items={[
          {
            title: 'Contributors',
            text: (
              <>
                About 2/3 of the way through each program, companies pledge <b>1% of their future equity</b> in the form
                of a Warrant.
              </>
            ),
            icon: <TimeIcon style={{ width: '37px', fill: 'var(--color-main)' }} />,
          },
          {
            title: 'Sharing',
            text: 'From that 2.5% share, any future financial returns from a liquidity event (for example, an IPO or acquisition) are distributed back to those who helped you, with the majority returned to your local ecosystem to support local growth.',
            icon: <ShareIcon />,
          },
          {
            title: '.5% Mentors',
            text: 'For providing feedback and guidance both during and after the mentorship program.',
          },
          {
            title: '1% Local Leaders',
            text: 'For running the local program and building the Astronomic community.',
          },
          {
            title: '1% Astronomic HQ',
            text: 'For providing assistance for years to come.',
          },
        ]}
      />

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title="Why Shared Equity?"
            text="Sit amet eu ullamcorper commodo mauris lectus dapibus mauris. Sem nunc elementum."
          />
        }
        buttonGroup={[
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              {GENERAL_CONTENT.SCHEDULE_A_MEETING}
            </MainButton>
          ),
        ]}
        buttonGroupCentered={true}
      >
        <Grid
          itemsInRow={2}
          gridVerticalMargin={!isUserLoggedIn ? 2 : 2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Aliquam ut lectus"
              text="Egestas sed vestibulum rutrum tortor natoque. Sed cursus tortor placerat quis. Viverra at et orci enim accumsan non dignissim. Sit venenatis maecenas eget arcu orci aliquam. Aliquam tortor nisl."
              icon={<PeopleIcon />}
              iconBgColor="#F4B84020"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Nisl molestie congue"
              text="In elit sed sed vitae non nibh faucibus. Cras donec posuere sed eu ornare luctus nibh viverra egestas. Mattis tempor in penatibus cras purus. Ut lorem faucibus in at bibendum. Vestibulum dolor neque."
              icon={<ThumbsUpIcon />}
              iconBgColor="#EA6B3D20"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Convallis in velit"
              text="Volutpat enim a senectus justo aliquam commodo rhoncus ullamcorper. Sed sit duis varius pharetra placerat nisi in. Sapien egestas elit quis justo urna pretium. Eget turpis sit turpis pulvinar id."
              icon={<ThumbsUp2Icon />}
              iconBgColor="#7AD2E720"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Et massa nisi aenean"
              text="Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet."
              icon={<CaseIcon />}
              iconBgColor="#80D0B820"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle title="Frequently Asked Questions" />}
        buttonGroupCentered={true}
      >
        <AccordionItem title="Vulputate sed viverra integer et nunc tincidunt. Sodales pharetra aliquet">
          Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus
          quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet.
        </AccordionItem>
        <AccordionItem title="Vulputate sed viverra integer et nunc tincidunt. Sodales pharetra aliquet">
          Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus
          quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet. Duis tellus at scelerisque
          malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus quisque massa fringilla vitae.
          Adipiscing sociis quam metus metus et massa laoreet. Duis tellus at scelerisque malesuada diam odio
          scelerisque pellentesque. Suspendisse facilisis faucibus quisque massa fringilla vitae. Adipiscing sociis quam
          metus metus et massa laoreet.
        </AccordionItem>
        <AccordionItem title="Vulputate sed viverra integer et nunc tincidunt. Sodales pharetra aliquet">
          Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus
          quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet.
        </AccordionItem>
        <AccordionItem title="Vulputate sed viverra integer et nunc tincidunt. Sodales pharetra aliquet">
          Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus
          quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet.
        </AccordionItem>
        <AccordionItem title="Vulputate sed viverra integer et nunc tincidunt. Sodales pharetra aliquet">
          Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus
          quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet.
        </AccordionItem>
        <AccordionItem title="Vulputate sed viverra integer et nunc tincidunt. Sodales pharetra aliquet">
          Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus
          quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet.
        </AccordionItem>
        <AccordionItem title="Vulputate sed viverra integer et nunc tincidunt. Sodales pharetra aliquet">
          Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus
          quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet.
        </AccordionItem>
      </ChildrenWithHeaderSection>

      {/* <SectionDivider color="gray" />

      <TestimonialsSection backgroundColor="gray" /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(PricingView);
