export enum UserRoleEnum {
  founder = "Founder",
  incubator = "Incubator",
  'business-owner' = "Business Owner",
  accelerator = "Accelerator",
  investor = "Investor",
  'venture-studio' = 'Venture Studio',
  freelancer = 'Freelancer',
  'coworking-space' = 'Coworking Space',
  consultant = 'Consultant',
  employee = 'Employee',
  agency = 'Agency',
  government = 'Government'
}