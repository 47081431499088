import { FunctionComponent, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import MainButton from '../../components/buttons/MainButton/MainButton';
import Grid from '../../components/blocks/Grid/Grid';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';

import { ReactComponent as MainIll } from '../../asset/images/connect_ill_3.svg';
import { ReactComponent as CalendarIcon } from '../../asset/images/icons/calendar_2.svg';
import { ReactComponent as GlassIcon } from '../../asset/images/icons/glass.svg';
import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';
import { ReactComponent as TickIcon } from '../../asset/images/icons/alert_success.svg';
import { ReactComponent as MinusIcon } from '../../asset/images/icons/minus.svg';
import { ReactComponent as VacationIcon } from '../../asset/images/icons/vacation.svg';
import { ReactComponent as PinIcon } from '../../asset/images/icons/pin.svg';
import { ReactComponent as PeopleIcon } from '../../asset/images/icons/people.svg';
import { ReactComponent as BubbleIcon } from '../../asset/images/icons/bubble.svg';
import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import WhatIsAstronomicPopUp from '../../components/popups/WhatIsAstronomicPopUp/WhatIsAstronomicPopUp';
import { GENERAL_CONTENT } from '../../data/generalContent';

interface GalaxyDinnersViewProps extends IWithUserRoleProps {}

const GalaxyDinnersView: FunctionComponent<GalaxyDinnersViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  const [isPopUpActive, setIsPopUpActive] = useState(false);

  const togglePopUp = () => {
    setIsPopUpActive(!isPopUpActive);
  };

  return (
    <MainTemplate color="white">
      <HeroSection
        title="Build relationships with other investors while growing your wealth"
        text="Join our membership club for accredited investors. Deal flow, dinners, and connections concierge."
        sectionVariation="var-1-a"
        illustration={<MainIll />}
        buttonGroup={[
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              {GENERAL_CONTENT.SCHEDULE_A_MEETING}
            </MainButton>
          ),
          <MainButton sizeType={'large'} visualType="white" onClick={togglePopUp}>
            {GENERAL_CONTENT.HOW_ASTRONOMIC_WORKS}
          </MainButton>,
        ]}
        breadcrumbs={<Breadcrumbs userRole={user_role} />}
      />

      <WhatIsAstronomicPopUp
        whatIsPopUpActive={isPopUpActive}
        changeWhatIsPopUpActiveState={togglePopUp}
        userRole={user_role}
      />

      <ChildrenWithHeaderSection title={<TitleWithSecTitle secTitle="An astral network" title="Features" />}>
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Monthly dinners"
              text={
                <>
                  Attend <b>monthly dinners at gorgeous homes</b> in cities everywhere. Review deals with like-minded
                  investors, followed by exquisite meals from culinary experts.
                </>
              }
              icon={<CalendarIcon style={{ width: '18px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Quarterly retreats"
              text={
                <>
                  Travel with other members to participate in <b>wellness retreats and service projects</b>. Sightsee,
                  experience culture, and build trust all at once.
                </>
              }
              icon={<PinIcon style={{ width: '20px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Annual gala"
              text={
                <>
                  Enjoy our annual celebration of wealth, health, connections, and impact with our broader Galaxy
                  family.
                </>
              }
              icon={<GlassIcon style={{ width: '14px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Timeless impact"
              text={
                <>
                  What happens once you have more than enough resources? <b>We’ll plan our legacies</b> and help each
                  other bring them to fruition.
                </>
              }
              icon={<TimeIcon style={{ width: '22px', fill: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <div className="invisible-divider"></div>

      <ChildrenWithHeaderSection title={<TitleWithSecTitle title="Format" />}>
        <Grid
          itemsInRow={3}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Accredited investors only"
              text="Members must be accredited private investors, however there is no requirement to invest in the deals we share."
              icon={<TickIcon style={{ fill: '#F4B840', width: '30px' }} />}
              iconBgColor="#F4B84020"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="10-person dinners"
              text="We meet monthly for 10-person Jeffersonian-style dinners with everyone sitting at the same table."
              icon={<PeopleIcon style={{ fill: 'var(--color-main)', width: '32px' }} />}
              iconBgColor="#EA6B3D20"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="No jerks"
              text="Galaxy is a safe space where soliciting is not allowed and where egos are checked at the door."
              icon={<MinusIcon style={{ fill: 'var(--color-green)', width: '24px' }} />}
              iconBgColor="#80D0B820"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Facilitated networking"
              text="We’ll help you meet each other Galaxy member so that you can become friends, co-investors, and collaborators."
              icon={<BubbleIcon style={{ fill: '#7AD2E7', width: '27px' }} />}
              iconBgColor="#7AD2E720"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Retreat planning"
              text="We’ll plan quarterly retreats for all Galaxy members to participate in, no matter where they’re based."
              icon={<VacationIcon style={{ fill: '#F4B840', width: '32px' }} />}
              iconBgColor="#F4B84020"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Join us!"
              text="Join the best network of accredited investors and begin building long-lasting friendships."
              icon={<RocketIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
              iconBgColor="#EA6B3D20"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      {/* <SectionDivider color="gray" />

      <TestimonialsSection backgroundColor="gray" /> */}
    </MainTemplate>
  );
};

export default with_user_role(GalaxyDinnersView);
