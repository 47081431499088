import React, { FunctionComponent, PropsWithChildren, useState, useEffect, Children } from 'react';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import { ReactComponent as ButtonArrow } from '../../../asset/images/button_arrow.svg';
import { ReactComponent as Logo } from '../../../asset/images/astronomic_logo_2.svg';
import classNames from 'classnames';
import styles from './ContentWithSidebar.module.scss';
import Label from '../../../components/texts/Label/Label';
import Content from '../../../components/texts/Content/Content';
import BackToTopButton from '../../../components/buttons/BackToTopButton/BackToTopButton';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';

interface ContentWithSidebarProps extends PropsWithChildren {}

const ContentWithSidebar: FunctionComponent<ContentWithSidebarProps> = ({ children }) => {
  const navigate = useNavigate();
  // const history = useHistory();

  const [activeTitle, setActiveTitle] = useState<number>(0);
  const [primaryTitles, setPrimaryTitles] = useState<[]>();

  const isMobile = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    const tempPrimaryTitles: any = [];
    console.log(children);
    Children.forEach(children, (child) => {
      if (React.isValidElement(child) && child.type === 'h5') {
        tempPrimaryTitles.push(child.props.children);
      }
    });
    setPrimaryTitles(tempPrimaryTitles);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const primaryTitleElements = document.querySelectorAll('h5');

      const positions = Array.from(primaryTitleElements).map((element, i) => {
        const rect = element.getBoundingClientRect();
        return {
          element: element.textContent,
          top: rect.top - window.innerHeight * 0.15,
          id: i,
        };
      });

      const closestPosition = positions.reduce((prev, curr) => (Math.abs(curr.top) < Math.abs(prev.top) ? curr : prev));

      setActiveTitle(closestPosition.id);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOnSidebarItemClick = (e) => {
    const primaryTitleElements = document.querySelectorAll('h5');
    const positions = Array.from(primaryTitleElements).map((element, i) => {
      const rect = element.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const yOffset = rect.top + scrollTop;

      return {
        top: yOffset - 200,
        id: i,
      };
    });

    positions.forEach((item, i) => {
      if (`primary_title_${item.id}` === e.target.id) {
        window.scrollTo({ top: item.top, behavior: 'smooth' });
      }
    });
  };

  return (
    <div className={styles['template']}>
      <header className={styles['header']}>
        <div className={'wrapper type-1'}>
          <div className={styles['header__part-1']}>
            {isMobile === false ? (
              <MainButton
                className={styles['back-button']}
                sizeType="small"
                visualType="white"
                iconLeft={<ButtonArrow style={{ width: '12px', transform: 'rotate(180deg)' }} />}
                onClick={() => navigate(-1)}
              >
                Back
              </MainButton>
            ) : (
              <MainButton
                className={styles['back-button']}
                sizeType="small"
                visualType="white"
                iconRight={<ButtonArrow style={{ width: '12px' }} />}
                onClick={() => navigate(-1)}
              >
                Back
              </MainButton>
            )}

            <Logo className={styles['logo']} />
          </div>
          <div className={styles['header__part-2']}>
            <h1 className={classNames(styles['title'], 'text text--h3')}>Astronomic Inc. Website Terms of Use</h1>

            <Label className={styles['last-modified-label']} visualStyle="white" text="Last Modified: June 22, 2021" />
          </div>
        </div>
      </header>

      <section className={styles['section']}>
        <div className={'wrapper type-1'}>
          <div className={styles['content']}>
            <aside className={styles['sidebar']}>
              <ul className={styles['sidebar__list']}>
                {primaryTitles?.map((item, i) => (
                  <li
                    key={i}
                    id={`primary_title_${i}`}
                    className={classNames(
                      styles['sidebar__list__item'],
                      'text text--caption',
                      activeTitle === i && styles['sidebar__list__item--active']
                    )}
                    onClick={handleOnSidebarItemClick}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </aside>

            <main className={styles['main']}>
              <Content>{children}</Content>
            </main>
          </div>
        </div>
      </section>

      {createPortal(<BackToTopButton>Back to top</BackToTopButton>, document.getElementById('modal') as HTMLElement)}
    </div>
  );
};

export default ContentWithSidebar;
