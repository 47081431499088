import { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./SectionDivider.module.scss";

type ColorType = "white" | "gray" | "blue";

const colorMap: Record<ColorType, string> = {
  white: "section-divider--color-white",
  gray: "section-divider--color-gray",
  blue: "section-divider--color-blue",
};

interface SectionDividerProps {
  className?: string;
  color?: ColorType;
}

const SectionDivider: FunctionComponent<SectionDividerProps> = ({
  className,
  color = "white",
}) => {
  return (
    <div
      className={classNames(
        "section-divider",
        styles["section-divider"],
        styles[colorMap[color]],
        className
      )}
    >
      <svg
        width="1440"
        height="96"
        viewBox="0 0 1440 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1440 96.5H1440.5V96V-7.62939e-06V-0.524803L1439.98 -0.49942L-0.0241699 69.2213L-0.5 69.2443V69.7207V96V96.5H0H1440Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </div>
  );
};

export default SectionDivider;
