import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../../../asset/images/astronomic_logo_3.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import styles from './ConfirmEmailTemplate.module.scss';
import { useOutletContext } from 'react-router-dom';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import InputMessage from '../../../../../components/texts/InputMessage/InputMessage';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import classNames from 'classnames';
import useFormSubmitLoader from '../../../../../hooks/useFormSubmitLoader';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';
import { get_env } from '../../../../../config/functions/app_functions';
import { GENERAL_CONTENT } from '../../../../../data/generalContent';

interface SignUpConfirmEmailProps {
  onSubmitApiRequest(userId: string, verificationCode: string): Promise<{ success: boolean; message: string }>;
  onGetDemoCodeApiRequest(userId: string): Promise<{ verificationCode: string }>;
  onRequestVerificationCodeApiRequest(userId: string): Promise<{ success: boolean }>;
}

const SignUpConfirmEmail: FunctionComponent<SignUpConfirmEmailProps> = ({
  onSubmitApiRequest,
  onGetDemoCodeApiRequest,
  onRequestVerificationCodeApiRequest,
}) => {
  const isMobile = useMediaQuery('(max-width: 1279px)');
  const { isLoading, handleFormSubmitLoader } = useFormSubmitLoader();

  const { changeWhatIsPopUpActiveState } =
    useOutletContext<{
      changeWhatIsPopUpActiveState;
    }>();

  const [email, setEmail] = useState(localStorage.getItem('userEmail') || 'NO_EMAIL');
  const [demoCode, setDemoCode] = useState('');
  const [code, setCode] = useState('');
  const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    setErrorMessage('');

    const clipboardText = event.clipboardData.getData('text');
    const sanitizedClipboardText = clipboardText.replace(/-/g, ''); // Remove hyphens
    if (sanitizedClipboardText.length === 6) {
      setCode(sanitizedClipboardText);
      setInputValues(sanitizedClipboardText.split(''));
    }
  };

  const handleInputChange = (index: number, value: string) => {
    setErrorMessage('');

    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.querySelector(`.input-${index + 1}`) as HTMLInputElement;

      if (nextInput) {
        nextInput.focus();
      }
    }

    // Format the code with hyphens as the user types
    const formattedCode = newInputValues.join('');
    setCode(formattedCode); // Limit to 7 characters (6 digits + 1 hyphen)
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    if (code === '') {
      setErrorMessage('Please, enter your code from email');
      return;
    }

    if (code.length < 6) {
      setErrorMessage('Please, fill in all characters');
      return;
    }

    // API LOGIC
    await handleFormSubmitLoader(async () => {
      const apiResponse = await onSubmitApiRequest(localStorage.getItem('userId') || '', code);

      if (apiResponse.success) {
        setSuccessMessage(apiResponse.message);
      } else {
        setSuccessMessage(''); // Clear success message in case of an error
        setErrorMessage(apiResponse.message);
      }
    });
    // API LOGIC END
  };

  const initialTime = 60; // Initial timer duration in seconds
  const [seconds, setSeconds] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const timerIntervalRef = useRef<number | null>(null);

  useEffect(() => {
    const storedStartTime = localStorage.getItem('timerStartTime');

    if (storedStartTime) {
      const savedTime = new Date(storedStartTime).getTime();
      const currentTime = new Date().getTime();
      const elapsedTime = Math.floor((currentTime - savedTime) / 1000);
      const remainingTime = initialTime - elapsedTime;

      if (remainingTime > 0 && isRunning === false) {
        clearIntervalTimer();
        localStorage.setItem('timerStartTime', storedStartTime);
        setSeconds(remainingTime);
        startTimer(remainingTime); // Pass the remaining time to startTimer
      }
    }
  }, []);

  const startTimer = (remainingTime: number) => {
    setIsRunning(true);

    timerIntervalRef.current = window.setInterval(() => {
      if (remainingTime > 0) {
        setSeconds(remainingTime - 1); // Update seconds state
        remainingTime--;
      } else {
        clearIntervalTimer();
      }

      if (timerIntervalRef.current === null) {
        clearIntervalTimer();
      }
    }, 1000);
  };

  const clearIntervalTimer = () => {
    if (timerIntervalRef.current !== null) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
      setIsRunning(false);
      setSeconds(initialTime);
      localStorage.removeItem('timerStartTime');
    }
  };

  const handleStart = async () => {
    if (!isRunning) {
      requestVerificationCode();

      const startTime = new Date();
      localStorage.setItem('timerStartTime', startTime.toISOString());
      startTimer(seconds);
    }
  };

  const handleOnGetDemoCode = async () => {
    // API LOGIC
    const apiResponse = await onGetDemoCodeApiRequest(localStorage.getItem('userId') || '');

    if (apiResponse?.verificationCode) setDemoCode(apiResponse?.verificationCode);
    // API LOGIC END
  };

  const requestVerificationCode = async () => {
    // API LOGIC
    const apiResponse = await onRequestVerificationCodeApiRequest(localStorage.getItem('userId') || '');

    if (apiResponse.success === true) {
      handleOnGetDemoCode();
    }
    // API LOGIC END
  };

  return (
    <div className={styles['container']}>
      <div className={styles['container-top-part']}>
        <NavigationLink
          path="/"
          iconLeft={<ArrowIcon style={{ width: '11px', transform: 'rotate(180deg)' }} />}
          withBorder={false}
          colorType="white"
        >
          Back to Homepage
        </NavigationLink>
      </div>

      <div className={styles['content']}>
        <CSSTransitionWrapper onEnter={isLoading} styleVariation="onForeground">
          <AstronomicLoader variation="blurredBackground" color="onBlue" />
        </CSSTransitionWrapper>

        <div className={styles['top-row']}>
          <div className={styles['astronomic-logo']}>{<AstronomicLogo />}</div>

          {isMobile === true && (
            <MainButton sizeType="medium" visualType="white" onClick={() => changeWhatIsPopUpActiveState(true)}>
              {GENERAL_CONTENT.WHAT_IS_ASTRONOMIC}
            </MainButton>
          )}
        </div>
        <div className={styles['title']}>
          <h3 className="text text--h3">Check your email</h3>
        </div>
        <div className={styles['text']}>
          <p className="text text--body-2">
            We've sent a 6-digit code to {email}. <br />
            The code expires shortly, so please enter it soon
          </p>
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className={styles['code-container']}>
            <div className={styles['code-container__input-group']}>
              {inputValues.slice(0, 3).map((value, index) => {
                return (
                  <input
                    className={`${styles['code-container__input-group__input']} input-${index}`}
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onPaste={handlePaste}
                  />
                );
              })}
            </div>

            <div className={styles['code-container__line']}></div>

            <div className={styles['code-container__input-group']}>
              {inputValues.slice(3).map((value, index) => {
                const newIndex = index + 3;

                return (
                  <input
                    className={`${styles['code-container__input-group__input']} input-${newIndex}`}
                    key={newIndex}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={(e) => handleInputChange(newIndex, e.target.value)}
                    onPaste={handlePaste}
                  />
                );
              })}
            </div>
          </div>

          {errorMessage && <InputMessage>{errorMessage}</InputMessage>}
          {successMessage && <InputMessage messageState="success">{successMessage}</InputMessage>}

          <div className={styles['button']}>
            <MainButton sizeType="large" disabled={isLoading}>
              Continue
            </MainButton>
          </div>
        </form>

        <div className={styles['resend']}>
          <p className="text text--body-2">Haven't received an email?</p>

          {isRunning === false ? (
            <NavigationLink path="#" sizeType="small" className={styles['resend__link']} onClick={handleStart}>
              Resend
            </NavigationLink>
          ) : (
            <NavigationLink
              path="#"
              sizeType="small"
              className={classNames(styles['resend__link'], styles['resend__link--disabled'])}
            >
              Resend
            </NavigationLink>
          )}
        </div>

        {isRunning === true && (
          <div className={styles['resend-timer']}>
            <p className="text text--body-2">
              Before next resend: <span className="text text--bold">{seconds}</span> sec
            </p>
          </div>
        )}

        {get_env() === 'staging' ||
          (get_env() === 'development' && (
            <div style={{ marginTop: '24px' }}>
              <MainButton sizeType="small" visualType="white" onClick={handleOnGetDemoCode}>
                Get demo code
              </MainButton>

              {demoCode && (
                <div style={{ color: 'var(--color-white)' }}>
                  <p style={{ marginTop: '24px' }} className={classNames('text text--body-2')}>
                    Demo code:
                  </p>

                  <p className={classNames('text text--body-2 text--bold')}>{demoCode}</p>
                </div>
              )}
            </div>
          ))}
      </div>

      <div className={styles['container-bottom-part']}></div>
    </div>
  );
};

export default SignUpConfirmEmail;
