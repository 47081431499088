import { FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as TimeIcon } from '../../../asset/images/icons/time.svg';
import classNames from 'classnames';
import styles from './Countdown.module.scss';

interface CountdownProps {
  colorStyle?: 'main' | 'gray';
  startDate: Date;
  endDate: Date;
  className?: string;
}

const Countdown: FunctionComponent<CountdownProps> = ({ colorStyle = 'main', startDate, endDate, className }) => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const initialTimeLeft = endDate.getTime() - startDate.getTime();

    const interval = setInterval(() => {
      timeLeft -= 1000;
      calculations(timeLeft);
    }, 1000);

    const calculations = (timeLeft: number) => {
      if (timeLeft <= 0) {
        clearInterval(interval);
        // You can perform an action when the countdown expires
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      }
    };

    let timeLeft = initialTimeLeft;
    calculations(timeLeft);

    return () => clearInterval(interval);
  }, [startDate, endDate]);

  const { days, hours, minutes, seconds } = countdown;

  return (
    <div className={classNames(styles['countdown'], styles[`countdown--color-${colorStyle}`], className)}>
      <div className={styles['countdown__icon']}>
        <TimeIcon style={{ fill: 'var(--color-white-50)', width: '22px' }} />
      </div>

      <p className={classNames(styles['countdown__text'], 'text text--caps-small')}>Expires in</p>

      <p className={classNames(styles['countdown__timer'], 'text text--body-2 text--bold')}>
        {days < 10 ? `0${days}` : days}:{hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </p>
    </div>
  );
};

export default Countdown;
