import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './AccountDashboard.module.scss';
import ServiceCard from '../../../../../components/blocks/ServiceCard/ServiceCard';
import HorizontalCard from '../../../../../components/blocks/HorizontalCard/HorizontalCard';
import { ReactComponent as ShareCardBG } from '../../../../../asset/images/share_card_bg.svg';
import { ReactComponent as ReferCardBG } from '../../../../../asset/images/refer_bg.svg';
import { ReactComponent as FbIcon } from '../../../../../asset/images/icons/fb.svg';
import { ReactComponent as LiIcon } from '../../../../../asset/images/icons/in.svg';
import { ReactComponent as TwIcon } from '../../../../../asset/images/icons/tw.svg';
import { ReactComponent as EmailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as ShareIcon } from '../../../../../asset/images/icons/share.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../../asset/images/icons/external_link.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import { createPortal } from 'react-dom';
import PopUp from '../../../../../components/popups/PopUp/PopUp';
import Input from '../../../../../components/inputs/Input/Input';
import ComingSoonMessage from '../../../../../components/texts/ComingSoonMessage/ComingSoonMessage';
import { sessionStorageGetItem, sessionStorageSetItem } from '../../../../../utils/sessionStorageMethods';
import { SESSION_STORAGE_KEYS } from '../../../../../enums/sessionStorageKeys';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../../../dto/api';
import api from '../../../../../api';
import PreloadProductCard from '../../../../../components/blocks/PreloadProductCard/PreloadProductCard';
import RoutesEnum from '../../../../../enums/routes';
import { stringToUserFriendlyURL, truncateString } from '../../../../../utils/stringFormatMethods';
import { UserRoleEnum } from '../../../../../enums/user';

interface AccountDashboardProps {}

const AccountDashboard: FunctionComponent<AccountDashboardProps> = () => {
  const [referPopUpActive, setSkipPopUpActive] = useState(false);
  const changeReferPopUpActiveState = (state) => {
    setSkipPopUpActive(state);
  };

  const [services, setServices] = useState<Array<any>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let cacheData = JSON.parse(sessionStorageGetItem(SESSION_STORAGE_KEYS.CACHE_SERVICES)!);

    const getServices = async () => {
      try {
        const response = await api.Content.getServicesByCategoryAndStage({
          growthStageId: null,
          serviceCategoryId: null,
        });
        const responseData = response.data;

        if (responseData.success === true && responseData.data) {
          setServices(responseData.data);
          sessionStorageSetItem(SESSION_STORAGE_KEYS.CACHE_SERVICES, JSON.stringify(responseData.data));
        } else {
          console.error('Error:', responseData.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;
        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }
        console.error('Error: ', error);
      } finally {
        setLoading(false);
      }
    };

    if (cacheData) {
      setServices(cacheData);
      setLoading(false);
    } else {
      getServices();
    }
  }, []);

  return (
    <div className={styles['dashboard']}>
      {/* <div className={styles['where-you-left-off']}>
        <p className={classNames(styles['where-you-left-off__title'], 'text text--caps')}>
          Continue where you left off
        </p>

        <ServiceCard
          title="UI/UX design"
          text="Do web research into competitors and opportunities and/or call prospective customers."
          link="/"
          size="small"
          price={null}
        />
      </div> */}

      <div className={styles['share']}>
        <HorizontalCard
          className={styles['share__card']}
          textColor="var(--color-white)"
          backgroundColor="var(--color-space-blue-1)"
          backgroundIllustration={<ShareCardBG className={styles['share__bg-el']} />}
          footer={
            <div className={styles['share__footer']}>
              <div className={styles['share__footer__left']}>
                <p className={classNames(styles['share__text-1'], 'text text--h3')}>$25 in credits</p>
              </div>
              <div className={styles['share__footer__right']}>
                <div className={styles['share__footer__button-list']}>
                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={<FbIcon style={{ width: '13px', fillOpacity: '1' }} />}
                    disabled
                  >
                    Share
                  </MainButton>

                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={<LiIcon style={{ width: '13px', fillOpacity: '1' }} />}
                    disabled
                  >
                    Share
                  </MainButton>

                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={<TwIcon style={{ width: '13px', fillOpacity: '1' }} />}
                    disabled
                  >
                    Tweet
                  </MainButton>

                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={
                      <EmailIcon
                        style={{
                          width: '15px',
                          fillOpacity: '1',
                          fill: 'var(--color-white)',
                        }}
                      />
                    }
                    disabled
                  >
                    Share
                  </MainButton>

                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconLeft={
                      <ShareIcon
                        style={{
                          width: '13px',
                          fillOpacity: '1',
                          fill: 'var(--color-white)',
                        }}
                      />
                    }
                    disabled
                  >
                    Share
                  </MainButton>
                </div>
              </div>
            </div>
          }
        >
          <p className={classNames(styles['share__title'], 'text text--caps')}>
            Share for Rewards! $25 in Credits Await
          </p>

          <p className={classNames(styles['share__text'], 'text text--body-2')}>
            Unlock rewards! Get $10 in credits for each friend you refer. Share the love and boost your benefits today!
          </p>
        </HorizontalCard>
      </div>

      <div className={styles['recommended']}>
        <p className={classNames(styles['recommended__title'], 'text text--caps')}>You might like it</p>

        <div className={styles['recommended__list']}>
          {services && services?.length > 0
            ? services
                ?.sort(() => 0.5 - Math.random())
                .slice(0, 4)
                .map((item, i) => {
                  return (
                    <ServiceCard
                      title={item.name}
                      text={truncateString(item.description, 200)}
                      price={null}
                      link={`/${Object.keys(UserRoleEnum)[0]}/${RoutesEnum.BUILD}/${stringToUserFriendlyURL(
                        item.name
                      )}`}
                      key={item.id}
                    />
                  );
                })
            : [1, 2, 3, 4].map((item, i) => <PreloadProductCard key={i} />)}
        </div>
      </div>

      <div className={styles['refer']}>
        <HorizontalCard
          className={styles['refer__card']}
          textColor="var(--color-white)"
          backgroundColor="var(--color-main)"
          backgroundIllustration={<ReferCardBG className={styles['refer__bg-el']} />}
          footer={
            <div className={styles['refer__footer']}>
              <div className={styles['refer__footer__left']}>
                <p className={classNames(styles['refer__text-1'], 'text text--h3')}>$50 in credits</p>
              </div>
              <div className={styles['refer__footer__right']}>
                <div className={styles['refer__footer__button-list']}>
                  <MainButton
                    visualType="blank"
                    sizeType="medium"
                    iconRight={<ExternalLinkIcon />}
                    onClick={() => changeReferPopUpActiveState(true)}
                  >
                    Share
                  </MainButton>
                </div>
              </div>
            </div>
          }
        >
          <p className={classNames(styles['refer__title'], 'text text--caps')}>Refer a Friend, Earn $50 in Credits</p>

          <p className={classNames(styles['refer__text'], 'text text--body-2')}>
            Unlock rewards! Get $10 in credits for each friend you refer. Share the love and boost your benefits today!
          </p>
        </HorizontalCard>
      </div>

      {/* <ComingSoonMessage type="overlay" /> */}

      {createPortal(
        <PopUp
          className={styles['refer-pop-up']}
          title="Refer a friend"
          active={referPopUpActive}
          onPopUpStateChange={changeReferPopUpActiveState}
          footer={
            <div className={styles['refer-pop-up__footer-button-list']}>
              <div className={styles['refer-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="white"
                  onClick={() => {
                    changeReferPopUpActiveState(false);
                  }}
                >
                  Cancel
                </MainButton>
              </div>

              <div className={styles['refer-pop-up__footer-button-list__item']}>
                <MainButton visualType="main" disabled>
                  Send emails and claim credits
                </MainButton>
              </div>
            </div>
          }
        >
          <p className={classNames(styles['refer-pop-up__text'], 'text text--body-2')}>
            Unlock rewards! Get $10 in credits for each friend you refer. Share the love and boost your benefits today!
          </p>

          <div className={styles['refer-pop-up__input-list']}>
            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #1" placeholder="Enter email address" size="large" name="refer_1" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #2" placeholder="Enter email address" size="large" name="refer_2" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #3" placeholder="Enter email address" size="large" name="refer_3" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #4" placeholder="Enter email address" size="large" name="refer_4" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--medium-margin-0">
              <div className="input-layout__row__col">
                <Input label="Friend #5" placeholder="Enter email address" size="large" name="refer_5" />
              </div>
            </div>
          </div>
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </div>
  );
};

export default AccountDashboard;
