import { FunctionComponent, useState, useEffect } from 'react';
import styles from './SignUpTemplate.module.scss';
import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';
import SignUpSliderWithIllustration from './_parts/SignUpSliderWithIllustration/SignUpSliderWithIllustration';
import { ReactComponent as ButtonArrow } from '../../../asset/images/button_arrow.svg';
import { ReactComponent as Background } from '../../../asset/images/signup_bg.svg';
import whatIsAstronomicPopUpBG from '../../../asset/images/what_is_astronomic_pop_up_bg.jpg';
import PopUp from '../../../components/popups/PopUp/PopUp';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import SectionDivider from '../../../components/sections/SectionDivider/SectionDivider';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const whatIsSliderStepsContentData = [
  {
    title: '',
    text: '',
    icon: '',
    nextButton: 'How it works?',
  },
  {
    title: 'No one goes to the moon alone',
    text: '<b>Think of us as your co-pilots, guiding you towards success</b>. We understand that estimating the time and cost for creative projects can be challenging, which is why we offer our services billed per hour. By joining our galaxy, you will have access to a wealth of resources that will help you reach the moon and beyond!',
    icon: '💫',
    nextButton: "Let's connect",
  },
  {
    title: "Let's connect",
    text: "<b>In our galaxy, you'll be connected with a community of like-minded individuals</b>. We’ll match you with other founders and investors who share your passion and ambition. Together, you'll be able to network, exchange ideas, and share experiences with people who understand what it takes to achieve success.",
    icon: '⚡️',
    nextButton: "Let's build together",
  },
  {
    title: `We’ll build your entire
    startup for you`,
    text: '<b>We have all the tools you need to design, build, launch, and scale your business or company</b>. Our specialists will guide you through every step of the process, from brainstorming ideas to launching your product or service. We pride ourselves on our clear communication and honesty. You will always know what to expect in terms of cost and timeline, and we will keep you updated every step of the way.',
    icon: '🚀',
    nextButton: 'Our pricing model',
  },
  {
    title: 'Our pricing model',
    text: 'We understand that creative projects can be difficult to estimate in terms of time and cost, <b>which is why we offer services billed per hour</b>. We believe that this pricing model is the most fair and transparent way to work with our clients. Instead of charging a fixed price that may not accurately reflect the time and effort required for your project, we bill only for the time spent working on it.',
    icon: '🤝',
    nextButton: "Let's talk",
  },
  {
    title: "Let's talk",
    text: "<b>Once you sign up with us, you'll have access to all our services</b>. You can schedule a kickoff call with our team at any time to discuss your project, ask questions, and receive guidance. Join our galaxy today and let us help you soar to new heights.",
    icon: '👋',
    nextButton: 'Join our galaxy',
  },
];

interface SignUpTemplateProps {}

const SignUpTemplate: FunctionComponent<SignUpTemplateProps> = ({}) => {
  const isTablet = useMediaQuery('(max-width: 1279px)');
  const location = useLocation();
  const [from, setFrom] = useState<string>();
  const [secondRerender, setSecondRerender] = useState<Boolean>(false);

  useEffect(() => {
    if (location.state) {
      if (location.state.from) {
        setFrom(location.state.from);
      }
    }

    setSecondRerender(true);
  }, [location, secondRerender]);

  const [whatIsPopUpActive, setWhatIsPopUpActive] = useState(false);
  const [whatIsPopUpCurrentStep, setWhatIsPopUpCurrentStep] = useState(0);

  const changeWhatIsPopUpActiveState = (state) => {
    setWhatIsPopUpActive(state);

    if (state === true) {
      setWhatIsPopUpCurrentStep(0);
    }
  };

  useGSAP(
    () => {
      const tl = gsap.timeline();

      if (isTablet) {
        tl.set('.gsap__ill', { y: -300 }, 'one');
        // tl.set('.gsap__ill', { x: -300 }, 'one');
      } else {
        tl.set('.gsap__ill', { x: -300 }, 'one');
      }

      tl.fromTo('.gsap__right-part', { opacity: 0 }, { duration: 0.3, opacity: 1, ease: 'power1.out' }, 'one')
        .fromTo(
          '.gsap__ill',
          { opacity: 0, scale: 1.38 },
          { duration: 0.4, opacity: 1, scale: 1.4, ease: 'power3.Out' },
          'one'
        )
        .to('.gsap__ill', { y: '+=6', repeat: 8, yoyo: true, duration: 0.075, ease: 'power3.inOut' }, 'one')

        .fromTo('.gsap__bg', { opacity: 0 }, { delay: 0.2, duration: 0.5, opacity: 1, ease: 'power1.out' }, 'two')
        .fromTo(
          '.gsap__left-part',
          { opacity: 0, x: 40 },
          { delay: 0.2, duration: 0.8, opacity: 1, x: 0, ease: 'power2.inOut' },
          'two'
        )
        .to('.gsap__ill', { y: '+=3', repeat: 8, yoyo: true, duration: 0.075, ease: 'power2.inOut' }, 'two')
        .to('.gsap__ill', { duration: 1, x: 0, y: 0, scale: 1, ease: 'power2.inOut' }, 'two')
        .to('.gsap__right-part', { duration: 0.1, overflow: 'hidden', ease: 'power1.out' }, 'three');
      tl.progress(1);

      if (from === 'startup') {
        tl.progress(0);
        tl.play();
      }
    },
    { dependencies: [from], scope: '.sign-up-template', revertOnUpdate: true }
  );

  return (
    <main className={classNames(styles['container'], 'template sign-up-template')}>
      <div className={classNames(styles['background'], 'background')}>
        <div className={classNames(styles['bg-1'], 'gsap__bg')}>
          <Background />
        </div>
      </div>

      {secondRerender && (
        <>
          <section className={classNames(styles['container__left-part'], 'gsap__left-part')}>
            {
              <Outlet
                context={{
                  changeWhatIsPopUpActiveState,
                }}
              />
            }
          </section>

          <section className={classNames(styles['container__right-part'], 'gsap__right-part')}>
            {<SignUpSliderWithIllustration changeWhatIsPopUpActiveState={changeWhatIsPopUpActiveState} />}
          </section>
        </>
      )}

      {createPortal(
        <PopUp
          className={classNames(
            styles['what-is-pop-up'],
            whatIsPopUpCurrentStep > 0 && styles['what-is-pop-up--not-first-slide']
          )}
          active={whatIsPopUpActive}
          onPopUpStateChange={changeWhatIsPopUpActiveState}
          popUpWidth="720"
          footer={
            <div className={styles['what-is-pop-up__footer-button-list']}>
              <div className={styles['what-is-pop-up__footer-button-list__item']}>
                {whatIsPopUpCurrentStep === 0 ? (
                  <MainButton
                    visualType="blank"
                    onClick={() => {
                      changeWhatIsPopUpActiveState(false);
                    }}
                  >
                    Join our galaxy
                  </MainButton>
                ) : (
                  <MainButton
                    visualType="white"
                    iconLeft={
                      <ButtonArrow
                        style={{
                          fill: 'var(--color-main)',
                          width: '16px',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    }
                    onClick={() => {
                      setWhatIsPopUpCurrentStep(whatIsPopUpCurrentStep - 1);
                    }}
                  >
                    Back
                  </MainButton>
                )}
              </div>

              <div className={styles['what-is-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="main"
                  iconRight={<ButtonArrow style={{ fill: 'var(--color-white)', width: '16px' }} />}
                  onClick={() => {
                    if (whatIsSliderStepsContentData.length - 1 === whatIsPopUpCurrentStep) {
                      changeWhatIsPopUpActiveState(false);
                    } else {
                      setWhatIsPopUpCurrentStep(whatIsPopUpCurrentStep + 1);
                    }
                  }}
                >
                  {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].nextButton}
                </MainButton>
              </div>
            </div>
          }
        >
          <img
            className={styles['what-is-pop-up__bg']}
            src={whatIsAstronomicPopUpBG}
            alt="What is Astronomic background"
          />

          {whatIsPopUpCurrentStep > 0 && (
            <div className={styles['what-is-pop-up__progress-bar']}>
              <div
                className={styles['what-is-pop-up__progress-bar__indicator']}
                style={{
                  width: `${(whatIsPopUpCurrentStep / (whatIsSliderStepsContentData.length - 1)) * 100}%`,
                }}
              ></div>
            </div>
          )}

          {whatIsPopUpCurrentStep === 0 ? (
            <div className={styles['what-is-pop-up__slide']}>
              <div className={styles['what-is-pop-up__slide__inner']}>
                <p className={classNames(styles['what-is-pop-up__sub-title'], 'text text--caps')}>
                  Our mission is clear
                </p>

                <h3 className={classNames(styles['what-is-pop-up__title'], 'text text--h3')}>
                  To be the operating system for building <b>every stage</b> of your startup, from concept to
                  acquisition
                </h3>
              </div>
            </div>
          ) : (
            <div className={classNames(styles['what-is-pop-up__slide'], styles['what-is-pop-up__slide--not-first'])}>
              <div className={styles['what-is-pop-up__slide__inner']}>
                <div className={styles['what-is-pop-up__icon-container']}>
                  <div className={styles['what-is-pop-up__icon-container__icon']}>
                    {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].icon}
                  </div>
                </div>

                <p className={classNames(styles['what-is-pop-up__steps'], 'text text--caps')}>
                  Step {whatIsPopUpCurrentStep} of 5
                </p>

                <h3 className={classNames(styles['what-is-pop-up__title-2'], 'text text--h3')}>
                  {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].title}
                </h3>

                <p
                  className={classNames(styles['what-is-pop-up__text'], 'text text--body-2')}
                  dangerouslySetInnerHTML={{
                    __html: whatIsSliderStepsContentData[whatIsPopUpCurrentStep].text,
                  }}
                ></p>
              </div>
            </div>
          )}
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </main>
  );
};

export default SignUpTemplate;
