import { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./HeroSectionWithVerticalContent.module.scss";
import BackgroundSectionTemplate, {
  BackgroundSectionTemplateColorType,
} from "../sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate";

interface HeroSectionWithVerticalContentProps {
  title: string | React.ReactElement;
  text: string;
  illustration?: React.ReactElement;
  sectionBackgroundColor?: BackgroundSectionTemplateColorType;
}

const HeroSectionWithVerticalContent: FunctionComponent<HeroSectionWithVerticalContentProps> =
  ({ title, text, illustration, sectionBackgroundColor }) => {
    return (
      <section className={classNames(styles["section"])}>
        <BackgroundSectionTemplate
          color={sectionBackgroundColor}
        ></BackgroundSectionTemplate>

        <div className={"wrapper"}>
          <div className="content">
            <div className={styles["title"]}>
              <h1 className="text text--h1">{title}</h1>
            </div>

            <div className={styles["text"]}>
              <p className="text text--body-1">{text}</p>
            </div>

            {illustration && (
              <div className={styles["illustration"]}>{illustration}</div>
            )}
          </div>
        </div>
      </section>
    );
  };

export default HeroSectionWithVerticalContent;
