import { FunctionComponent } from "react";
import ContentWithSidebar from "../../containers/templates/ContentWithSidebar/ContentWithSidebar";

interface TermsOfUseViewProps {}

const TermsOfUseView: FunctionComponent<TermsOfUseViewProps> = () => {
  return (
    <ContentWithSidebar>
      <h5>Acceptance of the Terms of Use</h5>
      <p>
        These terms of use are entered into by and between You and Astronomic
        Inc, a Delaware corporation (“<b>Company</b>,” “<b>we</b>,” or “
        <b>us</b>”). The following terms and conditions, together with any
        documents they expressly incorporate by reference (collectively, “
        <b>Terms of Use</b>”), govern your access to and use of Astronomic.com,
        including any content, functionality, and services offered on or through
        Astronomic.com (the “<b>Website</b>”), including but not limited to
        Astronomic Agency, Astronomic Cloud, Astronomic Corporate, Astronomic
        Network, Astronomic Studio, Astronomic Ventures, and investoremails.com,
        whether as a guest or a registered user.
      </p>
      <p>
        Please read the Terms of Use carefully before you start to use the
        Website.
      </p>

      <h5>Changes to the Terms of Use</h5>
      <p>
        These terms of use are entered into by and between You and Astronomic
        Inc, a Delaware corporation (“<b>Company</b>,” “<b>we</b>,” or “
        <b>us</b>”). The following terms and conditions, together with any
        documents they expressly incorporate by reference (collectively, “
        <b>Terms of Use</b>”), govern your access to and use of Astronomic.com,
        including any content, functionality, and services offered on or through
        Astronomic.com (the “<b>Website</b>”), including but not limited to
        Astronomic Agency, Astronomic Cloud, Astronomic Corporate, Astronomic
        Network, Astronomic Studio, Astronomic Ventures, and investoremails.com,
        whether as a guest or a registered user.
      </p>
      <p>
        Please read the Terms of Use carefully before you start to use the
        Website.
      </p>

      <h5>Accessing the Website and Account Security</h5>
      <p>
        These terms of use are entered into by and between You and Astronomic
        Inc, a Delaware corporation (“<b>Company</b>,” “<b>we</b>,” or “
        <b>us</b>”). The following terms and conditions, together with any
        documents they expressly incorporate by reference (collectively, “
        <b>Terms of Use</b>”), govern your access to and use of Astronomic.com,
        including any content, functionality, and services offered on or through
        Astronomic.com (the “<b>Website</b>”), including but not limited to
        Astronomic Agency, Astronomic Cloud, Astronomic Corporate, Astronomic
        Network, Astronomic Studio, Astronomic Ventures, and investoremails.com,
        whether as a guest or a registered user.
      </p>
      <p>
        Please read the Terms of Use carefully before you start to use the
        Website.
      </p>

      <h5>Intellectual Property Rights</h5>
      <p>
        These terms of use are entered into by and between You and Astronomic
        Inc, a Delaware corporation (“<b>Company</b>,” “<b>we</b>,” or “
        <b>us</b>”). The following terms and conditions, together with any
        documents they expressly incorporate by reference (collectively, “
        <b>Terms of Use</b>”), govern your access to and use of Astronomic.com,
        including any content, functionality, and services offered on or through
        Astronomic.com (the “<b>Website</b>”), including but not limited to
        Astronomic Agency, Astronomic Cloud, Astronomic Corporate, Astronomic
        Network, Astronomic Studio, Astronomic Ventures, and investoremails.com,
        whether as a guest or a registered user.
      </p>

      <p>
        Please read the Terms of Use carefully before you start to use the
        Website.
      </p>

      <h5>Trademarks</h5>
      <p>
        These terms of use are entered into by and between You and Astronomic
        Inc, a Delaware corporation (“<b>Company</b>,” “<b>we</b>,” or “
        <b>us</b>”). The following terms and conditions, together with any
        documents they expressly incorporate by reference (collectively, “
        <b>Terms of Use</b>”), govern your access to and use of Astronomic.com,
        including any content, functionality, and services offered on or through
        Astronomic.com (the “<b>Website</b>”), including but not limited to
        Astronomic Agency, Astronomic Cloud, Astronomic Corporate, Astronomic
        Network, Astronomic Studio, Astronomic Ventures, and investoremails.com,
        whether as a guest or a registered user.
      </p>

      <p>
        Please read the Terms of Use carefully before you start to use the
        Website.
      </p>
    </ContentWithSidebar>
  );
};

export default TermsOfUseView;
