import { FunctionComponent, useState } from 'react';
import { createPortal } from 'react-dom';
import PopUp from '../PopUp/PopUp';
import classNames from 'classnames';
import styles from './WhatIsAstronomicPopUp.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import { ReactComponent as ButtonArrow } from '../../../asset/images/button_arrow.svg';
import whatIsAstronomicPopUpBG from '../../../asset/images/what_is_astronomic_pop_up_bg.jpg';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../../enums/routes';
import { GENERAL_CONTENT } from '../../../data/generalContent';
import { useAppSelector } from '../../../redux/hooks';
import { UserRoleEnum } from '../../../enums/user';

interface WhatIsAstronomicPopUpProps {
  whatIsPopUpActive: boolean;
  userRole?: UserRoleEnum;
  changeWhatIsPopUpActiveState: (state: boolean) => void;
}

const WhatIsAstronomicPopUp: FunctionComponent<WhatIsAstronomicPopUpProps> = ({
  whatIsPopUpActive,
  userRole = '',
  changeWhatIsPopUpActiveState,
}) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  const navigate = useNavigate();

  const [whatIsPopUpCurrentStep, setWhatIsPopUpCurrentStep] = useState(0);

  const handlePopUpClose = (state: boolean) => {
    changeWhatIsPopUpActiveState(state);
    setWhatIsPopUpCurrentStep(0);
  };

  const whatIsSliderStepsContentData = [
    {
      title: '',
      text: '',
      icon: '',
      nextButton: 'How it works?',
    },
    {
      title: 'No one goes to the moon alone',
      text: '<b>Think of us as your co-pilots, guiding you towards success</b>. We understand that estimating the time and cost for creative projects can be challenging, which is why we offer our services billed per hour. By joining our galaxy, you will have access to a wealth of resources that will help you reach the moon and beyond!',
      icon: '💫',
      nextButton: "Let's connect",
    },
    {
      title: "Let's connect",
      text: "<b>In our galaxy, you'll be connected with a community of like-minded individuals</b>. We’ll match you with other founders and investors who share your passion and ambition. Together, you'll be able to network, exchange ideas, and share experiences with people who understand what it takes to achieve success.",
      icon: '⚡️',
      nextButton: "Let's build together",
    },
    {
      title: `We’ll build your entire
      startup for you`,
      text: '<b>We have all the tools you need to design, build, launch, and scale your business or company</b>. Our specialists will guide you through every step of the process, from brainstorming ideas to launching your product or service. We pride ourselves on our clear communication and honesty. You will always know what to expect in terms of cost and timeline, and we will keep you updated every step of the way.',
      icon: '🚀',
      nextButton: 'Our pricing model',
    },
    {
      title: 'Our pricing model',
      text: 'We understand that creative projects can be difficult to estimate in terms of time and cost, <b>which is why we offer services billed per hour</b>. We believe that this pricing model is the most fair and transparent way to work with our clients. Instead of charging a fixed price that may not accurately reflect the time and effort required for your project, we bill only for the time spent working on it.',
      icon: '🤝',
      nextButton: "Let's talk",
    },
    {
      title: "Let's talk",
      text: "<b>Once you sign up with us, you'll have access to all our services</b>. You can schedule a kickoff call with our team at any time to discuss your project, ask questions, and receive guidance. Join our galaxy today and let us help you soar to new heights.",
      icon: '👋',
      nextButton: isUserLoggedIn ? GENERAL_CONTENT.SCHEDULE_A_MEETING : GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE,
    },
  ];

  return (
    <>
      {createPortal(
        <PopUp
          className={classNames(
            styles['what-is-pop-up'],
            whatIsPopUpCurrentStep > 0 && styles['what-is-pop-up--not-first-slide']
          )}
          active={whatIsPopUpActive}
          onPopUpStateChange={handlePopUpClose}
          popUpWidth="720"
          footer={
            <div className={styles['what-is-pop-up__footer-button-list']}>
              <div className={styles['what-is-pop-up__footer-button-list__item']}>
                {whatIsPopUpCurrentStep === 0 ? (
                  <MainButton
                    visualType="blank"
                    onClick={() => {
                      changeWhatIsPopUpActiveState(false);
                      navigate(`/${RoutesEnum.SIGN_UP}`);
                    }}
                  >
                    {GENERAL_CONTENT.JOIN_FOR_FREE}
                  </MainButton>
                ) : (
                  <MainButton
                    visualType="white"
                    iconLeft={
                      <ButtonArrow
                        style={{
                          fill: 'var(--color-main)',
                          width: '16px',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    }
                    onClick={() => {
                      setWhatIsPopUpCurrentStep(whatIsPopUpCurrentStep - 1);
                    }}
                  >
                    Back
                  </MainButton>
                )}
              </div>

              <div className={styles['what-is-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="main"
                  iconRight={<ButtonArrow style={{ fill: 'var(--color-white)', width: '16px' }} />}
                  onClick={() => {
                    if (whatIsSliderStepsContentData.length - 1 === whatIsPopUpCurrentStep) {
                      changeWhatIsPopUpActiveState(false);

                      if (isUserLoggedIn) {
                        navigate(`/${userRole}/${RoutesEnum.SCHEDULE_MEETING}`);
                      } else {
                        navigate(`/${RoutesEnum.SIGN_UP}`);
                      }
                    } else {
                      setWhatIsPopUpCurrentStep(whatIsPopUpCurrentStep + 1);
                    }
                  }}
                >
                  {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].nextButton}
                </MainButton>
              </div>
            </div>
          }
        >
          <img
            className={styles['what-is-pop-up__bg']}
            src={whatIsAstronomicPopUpBG}
            alt="What is Astronomic background"
          />

          {whatIsPopUpCurrentStep > 0 && (
            <div className={styles['what-is-pop-up__progress-bar']}>
              <div
                className={styles['what-is-pop-up__progress-bar__indicator']}
                style={{
                  width: `${(whatIsPopUpCurrentStep / (whatIsSliderStepsContentData.length - 1)) * 100}%`,
                }}
              ></div>
            </div>
          )}

          {whatIsPopUpCurrentStep === 0 ? (
            <div className={styles['what-is-pop-up__slide']}>
              <div className={styles['what-is-pop-up__slide__inner']}>
                <p className={classNames(styles['what-is-pop-up__sub-title'], 'text text--caps')}>
                  Our mission is clear
                </p>

                <h3 className={classNames(styles['what-is-pop-up__title'], 'text text--h3')}>
                  To be the operating system for building <b>every stage</b> of your startup, from concept to
                  acquisition
                </h3>
              </div>
            </div>
          ) : (
            <div className={classNames(styles['what-is-pop-up__slide'], styles['what-is-pop-up__slide--not-first'])}>
              <div className={styles['what-is-pop-up__slide__inner']}>
                <div className={styles['what-is-pop-up__icon-container']}>
                  <div className={styles['what-is-pop-up__icon-container__icon']}>
                    {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].icon}
                  </div>
                </div>

                <p className={classNames(styles['what-is-pop-up__steps'], 'text text--caps')}>
                  Step {whatIsPopUpCurrentStep} of 5
                </p>

                <h3 className={classNames(styles['what-is-pop-up__title-2'], 'text text--h3')}>
                  {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].title}
                </h3>

                <p
                  className={classNames(styles['what-is-pop-up__text'], 'text text--body-2')}
                  dangerouslySetInnerHTML={{
                    __html: whatIsSliderStepsContentData[whatIsPopUpCurrentStep].text,
                  }}
                ></p>
              </div>
            </div>
          )}
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </>
  );
};

export default WhatIsAstronomicPopUp;
