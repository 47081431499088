import { FunctionComponent, RefObject, useRef } from 'react';
import styles from './BalanceAndAccStatus.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../../enums/routes';

interface BalanceAndAccStatusProps {}

const BalanceAndAccStatus: FunctionComponent<BalanceAndAccStatusProps> = () => {
  const rocketFuelBalance = useAppSelector((state) => state.user.user?.rocketFuelBalance);
  const boostEndDate = useAppSelector((state) => state.user.user?.boostEndDate);
  const navigate = useNavigate();

  return (
    <div className={styles['add-info']}>
      <div
        className={styles['add-info__part-1']}
        onClick={() => navigate(`/${RoutesEnum.ACCOUNT}/${RoutesEnum.ROCKET_FUEL}`)}
      >
        <p className={classNames(styles['add-info__value'], 'text text--h5')}>${rocketFuelBalance}</p>

        <p className={classNames(styles['add-info__identifier'], 'text text--caps-small')}>Rocket Fuel</p>
      </div>

      <div
        className={styles['add-info__part-2']}
        onClick={() => navigate(`/${RoutesEnum.ACCOUNT}/${RoutesEnum.ROCKET_FUEL}/${RoutesEnum.ACCOUNT_BOOST}`)}
      >
        <p className={classNames(styles['add-info__value'], 'text text--h5')}>{boostEndDate ? 'Boosted' : 'Default'}</p>

        <p className={classNames(styles['add-info__identifier'], 'text text--caps-small')}>Account</p>
      </div>
    </div>
  );
};

export default BalanceAndAccStatus;
