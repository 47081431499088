import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as Logo } from '../../asset/images/astronomic_logo_3.svg';

import { ReactComponent as SlideOneCabin } from '../../asset/images/manifesto/slide_1_cabin.svg';
import { ReactComponent as SlideOneBgCircles } from '../../asset/images/manifesto/slide_1_bg_circles.svg';
import { ReactComponent as SlideOneBgStars } from '../../asset/images/manifesto/slide_1_bg_stars.svg';
import { ReactComponent as SlideOneBgPlanets } from '../../asset/images/manifesto/slide_1_bg_planets.svg';

import { ReactComponent as SlideTwoIll } from '../../asset/images/manifesto/slide_2_ill.svg';
import { ReactComponent as SlideTwoBgShape } from '../../asset/images/manifesto/slide_2_bg_shape.svg';

import { ReactComponent as SlideThreePersonOne } from '../../asset/images/manifesto/slide_3_person_1.svg';
import { ReactComponent as SlideThreePersonTwo } from '../../asset/images/manifesto/slide_3_person_2.svg';
import { ReactComponent as SlideThreeLight } from '../../asset/images/manifesto/slide_3_light.svg';
import { ReactComponent as SlideThreeLine } from '../../asset/images/manifesto/slide_3_line.svg';
import { ReactComponent as SlideThreeShape } from '../../asset/images/manifesto/slide_3_shape.svg';
import { ReactComponent as SlideThreeBgPlanets } from '../../asset/images/manifesto/slide_3_planets.svg';

import { ReactComponent as SlideFourIll } from '../../asset/images/manifesto/slide_4_ill.svg';

import { ReactComponent as SlideFiveIll } from '../../asset/images/manifesto/slide_5_ill.svg';

import { ReactComponent as SlideSixIllOne } from '../../asset/images/manifesto/slide_6_ill_1.svg';
import { ReactComponent as SlideSixIllTwo } from '../../asset/images/manifesto/slide_6_ill_2.svg';
import { ReactComponent as SlideSixIllThree } from '../../asset/images/manifesto/slide_6_ill_3.svg';
import { ReactComponent as SlideSixShape } from '../../asset/images/manifesto/slide_6_shape.svg';
import { ReactComponent as SlideSixBgPlanets } from '../../asset/images/manifesto/slide_6_planets.svg';

import { ReactComponent as Confetti } from '../../asset/images/manifesto/confetti.svg';

import classNames from 'classnames';
import styles from './ManifestoView.module.scss';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../enums/routes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUserInfoAsyncThunk } from '../../redux/slices/user/thunks';
import { localStorageGetItem } from '../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../enums/localStorageKeys';

const steps = [0, 0, 0, 0, 0, 0];

const useThrottle = (callback, delay) => {
  const lastCall = useRef(0);

  return useCallback(
    (...args) => {
      const now = new Date().getTime();
      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        callback(...args);
      }
    },
    [callback, delay]
  );
};

interface ManifestoViewProps {}

const ManifestoView: FunctionComponent<ManifestoViewProps> = () => {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [prevStep, setPrevStep] = useState(0);

  const isTablet = useMediaQuery('(max-width: 1279px)');
  const sectionRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const userFirstName = localStorageGetItem(LOCAL_STORAGE_KEYS.USER_FIRST_NAME);

  const navigateToCompleteProfile = () => {
    navigate(`/${RoutesEnum.COMPLETE_PROFILE}`);
  };

  const { contextSafe } = useGSAP(
    (context, contextSafe) => {
      context.clear();

      if (isTablet) return;

      const durationOut = 0.75;
      const durationIn = 1.25;
      const durationInitial = 0.33;
      const durationInitialTwo = 0.05;
      const easeIn = 'circ.out';
      const easeOut = 'circ.in';
      // const easeIn = 'power1.easeInOut';
      // const easeOut = 'power1.easeInOut';
      const delay = 0.1;

      const slideOneTo = () => {
        const slideOneTo = gsap.timeline();

        slideOneTo
          .to('body', { duration: durationInitial * 2 }, 'delay')
          .fromTo('.bg-slide-1', { opacity: 0, y: 0 }, { opacity: 1 }, 'one')
          .fromTo('.slide-1', { opacity: 0, y: 0, pointerEvents: 'none' }, { opacity: 1, pointerEvents: 'all' }, 'one')
          .fromTo(
            '.gsap_scroll-tip',
            { opacity: 0 },
            { opacity: 1, pointerEvents: 'all', delay: durationIn * 0.5, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap_slide-1-title',
            { opacity: 0, y: 120 },
            { opacity: 1, y: 0, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap_slide-1-text-1',
            { opacity: 0, y: 120 },
            { opacity: 1, y: 0, delay: delay, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap_slide-1-text-2',
            { opacity: 0, y: 120 },
            { opacity: 1, y: 0, delay: delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo('.gsap_cabin', { scale: 2 }, { scale: 1, duration: durationIn, ease: easeIn }, 'one')
          .fromTo(
            '.gsap_cabin-underlay',
            { scale: 1, y: 0, x: 0, opacity: 0 },
            { opacity: 1, duration: durationOut * 0.5, ease: easeOut },
            'one'
          )
          .fromTo(
            '.gsap_cabin-stars, .gsap_cabin-planets, .gsap_cabin-circles',
            { scale: 1, y: 0, opacity: 0 },
            { y: 0, opacity: 1, delay: delay, duration: durationOut, ease: easeOut },
            'one'
          );
      };

      const slideTwoTo = () => {
        const slideTwoTo = gsap.timeline();

        slideTwoTo
          .to('body', { duration: durationInitial }, 'delay')
          .fromTo('.bg-slide-2', { opacity: 0, y: 0 }, { opacity: 1 }, 'one')
          .fromTo('.slide-2', { opacity: 0, y: 0, pointerEvents: 'none' }, { opacity: 1, pointerEvents: 'all' }, 'one')
          .fromTo(
            '.gsap_slide-2-title',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap_slide-2-text',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, delay: delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-2-shape',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, delay: delay * 3, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__astro-group #Army > g, #orange-planet, #blue-planet, #green-planet, #small-planet-1, #small-planet-1_2',
            { scale: 1.4, opacity: 0, x: -40 },
            { opacity: 1, scale: 1, x: 0, stagger: 0.05, duration: durationIn, ease: easeIn },
            'one'
          );
      };

      const slideThreeTo = () => {
        const slideThreeTo = gsap.timeline();
        slideThreeTo
          .to('body', { duration: durationInitial }, 'delay')
          .fromTo('.bg-slide-3', { opacity: 0, y: 0 }, { opacity: 1 }, 'one')
          .fromTo('.slide-3', { opacity: 0, y: 0, pointerEvents: 'none' }, { opacity: 1, pointerEvents: 'all' }, 'one')
          .fromTo(
            '.gsap__slide-3-title',
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, delay: durationIn - delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-3-text',
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, delay: durationIn - delay, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__person-1',
            { opacity: 0, x: -100, y: -100 },
            { opacity: 1, y: 0, x: 0, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__person-2',
            { opacity: 0, x: 100, y: 100 },
            { opacity: 1, y: 0, x: 0, delay: delay, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__light',
            { opacity: 0, y: 0, scale: 0.1 },
            { opacity: 1, scale: 1, delay: delay * 2, duration: durationIn, ease: 'circ.inOut' },
            'one'
          )
          .fromTo(
            '.gsap__line',
            { opacity: 0, y: 0, x: -10 },
            { opacity: 1, x: 0, delay: durationIn, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__shape',
            { opacity: 0, y: 0, x: -40 },
            { opacity: 1, x: 0, delay: durationIn - delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-3-bg-planets',
            { opacity: 0, y: 100 },
            { opacity: 1, y: 0, delay: durationIn - delay * 4, duration: durationIn, ease: easeIn },
            'one'
          );
      };

      const slideFourTo = () => {
        const slideFourTo = gsap.timeline();

        slideFourTo
          .to('body', { duration: durationInitial }, 'delay')
          .fromTo('.bg-slide-4', { opacity: 0, y: 0 }, { opacity: 1 }, 'one')
          .fromTo('.slide-4', { opacity: 0, y: 0, pointerEvents: 'none' }, { opacity: 1, pointerEvents: 'all' }, 'one')
          .fromTo(
            '.gsap__slide-4-title',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-4-text',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, delay: delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-4-ill',
            { y: 120 * 2 },
            { y: 0, delay: delay * 3, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-4-ill > *',
            { opacity: 0 },
            { opacity: 1, stagger: -0.002, delay: delay * 3, duration: durationIn, ease: easeIn },
            'one'
          );
      };

      const slideFiveTo = () => {
        const slideFiveTo = gsap.timeline();
        slideFiveTo
          .to('body', { duration: durationInitial }, 'delay')
          .fromTo('.bg-slide-5', { opacity: 0, y: 0 }, { opacity: 1 }, 'one')
          .fromTo('.slide-5', { opacity: 0, y: 0, pointerEvents: 'none' }, { opacity: 1, pointerEvents: 'all' }, 'one')
          .fromTo(
            '.gsap__slide-5-title',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-5-text',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, delay: delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-5-ill',
            { y: 120 * 2 },
            { y: 0, delay: delay * 3, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-5-ill > *',
            { opacity: 0 },
            { opacity: 1, stagger: 0.005, delay: delay * 3, duration: durationIn, ease: easeIn },
            'one'
          );
      };

      const slideSixTo = () => {
        const slideSixTo = gsap.timeline();

        slideSixTo
          .to('body', { duration: durationInitial }, 'delay')
          .fromTo('.bg-slide-6', { opacity: 0, y: 0 }, { opacity: 1 }, 'one')
          .fromTo('.slide-6', { opacity: 0, y: 0, pointerEvents: 'none' }, { opacity: 1, pointerEvents: 'all' }, 'one')
          .fromTo(
            '.gsap__slide-6-title',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-6-text',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, delay: delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__fuel-item',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, stagger: 0.1, delay: delay * 3, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__button-outer',
            { opacity: 0, y: 120 * 2 },
            { opacity: 1, y: 0, delay: delay * 6, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-6-bg-shape',
            { opacity: 0, y: 0, x: -40 },
            { opacity: 1, x: 0, delay: durationIn - delay * 2, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo(
            '.gsap__slide-6-bg-planets',
            { opacity: 0, y: 100 },
            { opacity: 1, y: 0, delay: durationIn - delay * 4, duration: durationIn, ease: easeIn },
            'one'
          )
          .fromTo('.gsap__header-button', { opacity: 1 }, { opacity: 0, duration: durationIn, ease: easeIn }, 'one');
      };

      const slideOneFrom = () => {
        const slideOneFrom = gsap.timeline();
        slideOneFrom
          // .to('body', { duration: durationInitialTwo * 2 }, 'delay')
          .fromTo('.bg-slide-1', { opacity: 1 }, { opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo('.slide-1', { opacity: 1 }, { pointerEvents: 'none', opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo(
            '.gsap_slide-1-title',
            { opacity: 1, y: 0 },
            { opacity: 0, y: -120 * 10, rotationX: 0, duration: durationOut, ease: easeOut },
            'one'
          )
          .fromTo(
            '.gsap_slide-1-text-1',
            { opacity: 1, y: 0 },
            { opacity: 0, y: -120 * 10, rotationX: 0, duration: durationOut, ease: easeOut },
            'one'
          )
          .fromTo(
            '.gsap_slide-1-text-2',
            { opacity: 1, y: 0 },
            { opacity: 0, y: -120 * 10, rotationX: 0, duration: durationOut, ease: easeOut },
            'one'
          )
          .fromTo(
            '.gsap_cabin-stars, .gsap_cabin-planets',
            { opacity: 0 },
            { opacity: 0, delay: delay, duration: durationOut * 0.2 },
            'one'
          )
          .fromTo(
            '.gsap_scroll-tip',
            { opacity: 0 },
            { pointerEvents: 'none', opacity: 0, delay: delay, duration: 1 },
            'one'
          )
          .fromTo('.gsap_cabin', { scale: 1 }, { scale: 3, duration: durationOut, ease: easeOut }, 'one')
          .fromTo(
            '.gsap_cabin-circles, .gsap_cabin-underlay',
            { scale: 1, y: 0 },
            { y: 0, duration: durationOut, ease: easeOut },
            'one'
          );
      };

      const slideTwoFrom = () => {
        const slideTwoFrom = gsap.timeline();
        slideTwoFrom
          .fromTo('.bg-slide-2', { opacity: 1 }, { opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo('.slide-2', { opacity: 1 }, { pointerEvents: 'none', opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo(
            '.gsap_slide-2-title',
            { opacity: 1, y: 0 },
            { opacity: 0, y: -120 * 10, rotationX: 0, duration: durationOut, ease: easeOut },
            'one'
          )
          .fromTo(
            '.gsap_slide-2-text',
            { opacity: 1, y: 0 },
            { opacity: 0, y: -120 * 10, rotationX: 0, duration: durationOut, ease: easeOut },
            'one'
          )
          .fromTo(
            '.gsap__astro-group #Army > g, #orange-planet, #blue-planet, #green-planet, #small-planet-1, #small-planet-1_2',
            { opacity: 1, scale: 1, x: 0 },
            { duration: durationOut, ease: easeOut },
            'one'
          );
      };

      const slideThreeFrom = () => {
        const slideThreeFrom = gsap.timeline();
        slideThreeFrom
          .fromTo('.bg-slide-3', { opacity: 1 }, { opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo('.slide-3', { opacity: 1 }, { pointerEvents: 'none', opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo(
            '.gsap__slide-3-title, .gsap__person-1, .gsap__person-2, .gsap__light, .gsap__line, .gsap__shape, .gsap__slide-3-text',
            { opacity: 1, y: 0, x: 0, scale: 1 },
            { opacity: 0, y: -120 * 10, x: 0, scale: 1, stagger: 0.025, duration: durationOut, ease: easeOut },
            'one'
          );
      };

      const slideFourFrom = () => {
        const slideFourFrom = gsap.timeline();
        slideFourFrom
          .fromTo('.bg-slide-4', { opacity: 1 }, { opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo(
            '.slide-4',
            { opacity: 1 },
            { pointerEvents: 'none', opacity: 0, duration: durationOut * 1.2 },
            'one'
          );
      };

      const slideFiveFrom = () => {
        const slideFiveFrom = gsap.timeline();
        slideFiveFrom
          .fromTo('.bg-slide-5', { opacity: 1 }, { opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo(
            '.slide-5',
            { opacity: 1 },
            { pointerEvents: 'none', opacity: 0, duration: durationOut * 1.2 },
            'one'
          );
      };

      const slideSixFrom = () => {
        const slideSixFrom = gsap.timeline();
        slideSixFrom
          .fromTo('.bg-slide-6', { opacity: 1 }, { opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo('.slide-6', { opacity: 1 }, { pointerEvents: 'none', opacity: 0, duration: durationOut * 1.2 }, 'one')
          .fromTo('.gsap__header-button', { opacity: 0 }, { opacity: 1, duration: durationOut, ease: easeOut }, 'one');
      };

      const fromAnimTrigger = () => {
        if (prevStep === 1) {
          slideOneFrom();
        }

        if (prevStep === 2) {
          slideTwoFrom();
        }

        if (prevStep === 3) {
          slideThreeFrom();
        }

        if (prevStep === 4) {
          slideFourFrom();
        }

        if (prevStep === 5) {
          slideFiveFrom();
        }

        if (prevStep === 6) {
          slideSixFrom();
        }
      };

      context.clear();
      fromAnimTrigger();

      if (currentStep === 1) {
        slideOneTo();
      }

      if (currentStep === 2) {
        slideTwoTo();
      }

      if (currentStep === 3) {
        slideThreeTo();
      }

      if (currentStep === 4) {
        slideFourTo();
      }

      if (currentStep === 5) {
        slideFiveTo();
      }

      if (currentStep === 6) {
        slideSixTo();
      }

      if (prevStep !== 0) {
        gsap.fromTo(
          '.gsap__bg-space',
          { y: window.innerHeight * 2 * (prevStep - 1) },
          { y: window.innerHeight * 2 * (currentStep - 1), duration: durationIn * 1.25, ease: 'circ.inOut' }
        );
      } else {
        gsap.fromTo(
          '.gsap__bg-space',
          { y: window.innerHeight * 2 },
          { y: 0, duration: durationIn * 1.25, ease: 'circ.inOut' }
        );
      }

      // setTimeout(() => {
      //   slideOneTo.progress(0.999).play();
      //   slideOneFrom();
      //   slideTwoTo.play();

      //   gsap.fromTo(
      //     '.gsap__bg-space',
      //     { y: window.innerHeight * 2 * (1 - 1) },
      //     { y: window.innerHeight * 2 * (2 - 1), duration: durationIn * 1.25, ease: 'circ.inOut' }
      //   );
      // }, 2000);
    },
    { dependencies: [currentStep, isTablet], scope: sectionRef, revertOnUpdate: false }
  );

  const handleNavigation = contextSafe(
    useCallback(
      (e) => {
        const window = e.currentTarget;
        setPrevStep(currentStep);

        if (e.detail > 0 || e.wheelDelta < 0) {
          // down
          setCurrentStep((prev) => (prev + 1 > steps.length ? 1 : prev + 1));
        } else {
          // top
          setCurrentStep((prev) => (prev <= 1 ? steps.length : prev - 1));
        }
      },
      [setCurrentStep, currentStep]
    )
  );

  const handleStepChange = (index) => {
    setPrevStep(currentStep);

    if (index) {
      setCurrentStep(index);
    } else {
      setCurrentStep(1);
    }
  };

  const throttledHandleStepChange = useThrottle(handleStepChange, 1500);
  const throttledHandleNavigation = useThrottle(handleNavigation, 1500);

  useEffect(() => {
    window.addEventListener('wheel', throttledHandleNavigation);

    if (isTablet === true) window.removeEventListener('wheel', throttledHandleNavigation);

    return () => {
      window.removeEventListener('wheel', throttledHandleNavigation);
    };
  }, [throttledHandleNavigation, isTablet]);

  // layout effect lag spike fix
  useEffect(() => {
    // setCurrentStep(1);
  }, []);
  // layout effect lag spike fix end

  return (
    <section className={classNames(styles['section'])} ref={sectionRef}>
      <div className={classNames(styles['background'], 'background')}>
        <div className={classNames(styles['bg-space'], 'gsap__bg-space')}>
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
          <Confetti className={classNames(styles['bg-space__confetti-1'], styles['bg-space__confetti'])} />
        </div>

        <div className={styles['slide-list']}>
          <div className={classNames(styles['slide-list__item'], 'bg-slide-1')}>
            <SlideOneCabin className={classNames(styles['slide-1__bg-1'], 'gsap_cabin')} />
            <div className={classNames(styles['slide-1_bg-1-2'], 'gsap_cabin-underlay')}></div>
            <SlideOneBgCircles className={classNames(styles['slide-1__bg-2'], 'gsap_cabin-circles')} />
            <SlideOneBgStars className={classNames(styles['slide-1__bg-3'], 'gsap_cabin-stars')} />
            <SlideOneBgPlanets className={classNames(styles['slide-1__bg-4'], 'gsap_cabin-planets')} />
          </div>

          {isTablet === false && (
            <>
              <div className={classNames(styles['slide-list__item'], 'bg-slide-2')}></div>

              <div className={classNames(styles['slide-list__item'], 'bg-slide-3')}>
                <SlideThreeBgPlanets className={classNames(styles['slide-3__bg-1'], 'gsap__slide-3-bg-planets')} />
              </div>

              <div className={classNames(styles['slide-list__item'], 'bg-slide-4')}></div>

              <div className={classNames(styles['slide-list__item'], 'bg-slide-5')}></div>

              <div className={classNames(styles['slide-list__item'], 'bg-slide-6')}>
                <SlideSixBgPlanets className={classNames(styles['slide-6__bg-1'], 'gsap__slide-6-bg-planets')} />
                <SlideSixShape className={classNames(styles['slide-6__bg-2'], 'gsap__slide-6-bg-shape')} />
              </div>
            </>
          )}
        </div>
      </div>

      <header className={classNames(styles['header'])}>
        <div className="wrapper type-1">
          <div className={styles['header__layout']}>
            <div className={styles['header__layout__block-1']}>
              <Logo />
            </div>

            <div className={styles['header__layout__block-2']}>
              <div className={classNames(styles['header__button-outer'], 'gsap__header-button')}>
                <MainButton visualType="blue" onClick={navigateToCompleteProfile}>
                  Skip
                </MainButton>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className={styles['main']}>
        <div className="wrapper type-1">
          <div className={styles['content']}>
            <div className={styles['container']}>
              <div className={styles['slide-list']}>
                <div className={classNames(styles['slide-list__item'], styles['slide-list__item-1'], 'slide-1')}>
                  <div className={styles['slide-list__item__container']}>
                    <div className={styles['slide-list__item__content']}>
                      <p className={classNames(styles['text-1'], 'text text--h2', 'gsap_slide-1-title')}>
                        {userFirstName ? (
                          <>
                            Welcome to <br />
                            Astronomic, {userFirstName}! ⚡️
                          </>
                        ) : (
                          <>
                            Welcome to <br />
                            Astronomic! ⚡️
                          </>
                        )}
                      </p>

                      <p className={classNames(styles['text-2'], 'text text--h4', 'gsap_slide-1-text-1')}>
                        The Astronomic Manifesto
                      </p>

                      <p className={classNames(styles['text-3'], 'text text--body-1', 'gsap_slide-1-text-2')}>
                        Here we are… human beings on one planet... in a galaxy with billions of planets… in a universe
                        with billions of galaxies. With threats of climate change, AI takeover, and world war, wouldn’t
                        it be a shame if we perished before capitalizing on an opportunity to explore the cosmos?
                      </p>
                    </div>
                  </div>
                </div>

                <div className={classNames(styles['slide-list__item'], styles['slide-list__item-2'], 'slide-2')}>
                  <div className={styles['slide-list__item__container']}>
                    <div className={styles['slide-list__item__content']}>
                      <div className={styles['slide-list__item__layout']}>
                        <div className={styles['slide-list__item__layout__item-1']}>
                          <p className={classNames(styles['text-1'], 'text text--h2', 'gsap_slide-2-title')}>
                            We need as many <mark>entrepreneurs</mark> as possible...
                          </p>

                          <p className={classNames(styles['text-2'], 'text text--body-1', 'gsap_slide-2-text')}>
                            At Astronomic, our mission is to make entrepreneurship as accessible as possible.
                            Entrepreneurship teaches humility, courage, and perseverance. It unites those with opposing
                            beliefs and it moves us forward.
                          </p>
                        </div>
                        <div className={styles['slide-list__item__layout__item-2']}>
                          <SlideTwoIll className={classNames(styles['ill'], 'gsap__astro-group')} />
                          <SlideTwoBgShape className={classNames(styles['shape'], 'gsap__slide-2-shape')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames(styles['slide-list__item'], styles['slide-list__item-3'], 'slide-3')}>
                  <div className={styles['slide-list__item__container']}>
                    <div className={styles['slide-list__item__content']}>
                      <p className={classNames(styles['text-1'], 'text text--h2', 'gsap__slide-3-title')}>
                        To <mark>unite</mark> around our common interests
                      </p>

                      <div className={classNames(styles['person-group'])}>
                        <SlideThreePersonOne className={classNames(styles['person-1'], 'gsap__person-1')} />
                        <SlideThreePersonTwo className={classNames(styles['person-2'], 'gsap__person-2')} />

                        <SlideThreeLight className={classNames(styles['light'], 'gsap__light')} />
                        <SlideThreeLine className={classNames(styles['line'], 'gsap__line')} />
                        <SlideThreeShape className={classNames(styles['shape'], 'gsap__shape')} />
                      </div>

                      <p className={classNames(styles['text-2'], 'text text--body-2', 'gsap__slide-3-text')}>
                        Anyone who enables entrepreneurship is our ally. Our fight is bigger than who controls more
                        market share. It’s about the future of our humanity. We need to lower our egos, embrace each
                        other’s differences, and unite to serve our fellow humans. To this end, we need you to start
                        your own company, and we need you to support others who build companies. 
                      </p>
                    </div>
                  </div>
                </div>

                <div className={classNames(styles['slide-list__item'], styles['slide-list__item-4'], 'slide-4')}>
                  <div className={styles['slide-list__item__container']}>
                    <div className={styles['slide-list__item__content']}>
                      <div className={styles['slide-list__item__layout']}>
                        <div className={styles['slide-list__item__layout__item-1']}>
                          <p className={classNames(styles['text-1'], 'text text--h2', 'gsap__slide-4-title')}>
                            and elevate our <mark>humanity</mark> to new heights!
                          </p>

                          <p className={classNames(styles['text-2'], 'text text--body-1', 'gsap__slide-4-text')}>
                            As we embark on this journey together, we’ll work hard to serve your interests ethically,
                            affordably, and efficiently. If we can improve, please let us know.
                            <br />
                            <br />
                            Together, we all have incredible collective potential. The sky’s not the limit — it’s just
                            the beginning! 🚀
                          </p>
                        </div>
                        <div className={styles['slide-list__item__layout__item-2']}>
                          <SlideFourIll className={classNames(styles['ill'], 'gsap__slide-4-ill')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames(styles['slide-list__item'], styles['slide-list__item-5'], 'slide-5')}>
                  <div className={styles['slide-list__item__container']}>
                    <div className={styles['slide-list__item__content']}>
                      <div className={styles['slide-list__item__layout']}>
                        <div className={styles['slide-list__item__layout__item-1']}>
                          <SlideFiveIll className={classNames(styles['ill'], 'gsap__slide-5-ill')} />
                        </div>
                        <div className={styles['slide-list__item__layout__item-2']}>
                          <p className={classNames(styles['text-1'], 'text text--h2', 'gsap__slide-5-title')}>
                            Turbocharge your journey with <mark>Rocket Fuel</mark>
                          </p>

                          <p className={classNames(styles['text-2'], 'text text--body-1', 'gsap__slide-5-text')}>
                            As they say, it takes money to make money. But we’d like to make it easier for you — and
                            more fun. To that end, <mark>you’ll earn $5 in Rocket Fuel credits</mark> for every $100 you
                            spend with us. You can use your Rocket Fuel credits on any of our products and services,
                            site-wide.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames(styles['slide-list__item'], styles['slide-list__item-6'], 'slide-6')}>
                  <div className={styles['slide-list__item__container']}>
                    <div className={styles['slide-list__item__content']}>
                      <div className={classNames(styles['text-outer'])}>
                        <p className={classNames(styles['text-1'], 'text text--h2', 'gsap__slide-6-title')}>
                          Want to go faster?
                        </p>

                        <p className={classNames(styles['text-2'], 'text text--body-1', 'gsap__slide-6-text')}>
                          <mark>Partner with us</mark> and we’ll forego margin. Grant equity to Astronomic and get a
                          discount on our products and services for one year.
                          <br />
                          <br />
                          Share 1% equity from your company to Astronomic and boost your account for 1 year. Receive a
                          10% discount on all Astronomic products and services as well as 2x the Rocket Fuel credits for
                          2% or a 25% discount for 3%.
                        </p>
                      </div>

                      <div className={classNames(styles['fuel-group'])}>
                        <div className={classNames(styles['fuel-group__item'], 'gsap__fuel-item')}>
                          <SlideSixIllOne className={classNames(styles['fuel-group__item__ill'])} />

                          <div className={classNames(styles['fuel-group__item__percent'])}>1%</div>

                          <p className={classNames(styles['fuel-group__item__sub-title'], 'text text--body-2')}>
                            Share 1% and gain
                          </p>

                          <p className={classNames(styles['fuel-group__item__title'], 'text text--h4')}>
                            2x Rocket Fuel.
                          </p>
                        </div>

                        <div className={classNames(styles['fuel-group__item'], 'gsap__fuel-item')}>
                          <SlideSixIllTwo className={classNames(styles['fuel-group__item__ill'])} />

                          <div className={classNames(styles['fuel-group__item__percent'])}>2%</div>

                          <p className={classNames(styles['fuel-group__item__sub-title'], 'text text--body-2')}>
                            Share 2% and gain
                          </p>

                          <p className={classNames(styles['fuel-group__item__title'], 'text text--h4')}>
                            3x Rocket Fuel.
                          </p>
                        </div>

                        <div className={classNames(styles['fuel-group__item'], 'gsap__fuel-item')}>
                          <SlideSixIllThree className={classNames(styles['fuel-group__item__ill'])} />

                          <div className={classNames(styles['fuel-group__item__percent'])}>3%</div>

                          <p className={classNames(styles['fuel-group__item__sub-title'], 'text text--body-2')}>
                            Share 3% and gain
                          </p>

                          <p className={classNames(styles['fuel-group__item__title'], 'text text--h4')}>
                            5x Rocket Fuel.
                          </p>
                        </div>
                      </div>

                      <div className={classNames(styles['button-outer'], 'gsap__button-outer')}>
                        <MainButton onClick={navigateToCompleteProfile}>Get started</MainButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {isTablet === false && (
        <aside className={styles['aside']}>
          <div className="wrapper type-1">
            <div className={styles['content']}>
              <div className={styles['container']}>
                <div className={styles['step-list']}>
                  {steps.map((item, i) => (
                    <div
                      className={classNames(
                        styles['step-list__item'],
                        currentStep === i + 1 && styles['step-list__item--active']
                      )}
                      key={i}
                      onClick={() => throttledHandleStepChange(i + 1)}
                    >
                      <div className={styles['step-list__item__text']}>{i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </aside>
      )}

      <footer className={styles['footer']}>
        <div className="wrapper type-1">
          <div className={styles['content']}>
            <div className={classNames(styles['scroll-tip'], 'gsap_scroll-tip')}>
              <p className={classNames(styles['scroll-tip__text'], 'text text--body-1 text--bold')}>
                Scroll to begin your journey!
              </p>

              <div className={styles['scroll-tip__icon']}>
                <svg width="20" height="12" viewBox="0 0 20 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.05719 1.05654C1.57789 0.535841 2.42211 0.535841 2.94281 1.05654L10 8.11373L17.0572 1.05654C17.5779 0.535841 18.4221 0.535841 18.9428 1.05654C19.4635 1.57724 19.4635 2.42146 18.9428 2.94216L10.9428 10.9422C10.4221 11.4629 9.57789 11.4629 9.05719 10.9422L1.05719 2.94216C0.536489 2.42146 0.536489 1.57724 1.05719 1.05654Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default ManifestoView;
