import { FunctionComponent, useState } from 'react';
import styles from './CheckboxList.module.scss';
import Checkbox from './_parts/Checkbox/Checkbox';
import classNames from 'classnames';
import { InputData } from '../../../containers/templates/CompleteProfileTemplate/utils/types';
export interface CheckboxData {
  value: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
}

interface CheckboxListProps {
  sizeSmall?: boolean;
  name: string;
  data: CheckboxData[];
  dataToSetCheckedState?: boolean[];
  checkboxInitialData?: InputData[];
  className?: string;
  onChange?(data): void;
}

const CheckboxList: FunctionComponent<CheckboxListProps> = ({
  sizeSmall,
  data,
  dataToSetCheckedState,
  name,
  checkboxInitialData,
  className,
  onChange,
}) => {
  const [checkboxData, setCheckboxData] = useState<InputData[]>(checkboxInitialData || []);

  const onChangeHandler = (data: InputData) => {
    const newData = [...checkboxData];

    const valueIndex = newData.findIndex((item) => item.value === data.value);

    if (valueIndex === -1) {
      newData.push({ name: data.name, value: data.value });
    } else {
      newData.splice(valueIndex, 1);
    }

    setCheckboxData(newData);
    onChange?.(newData);
  };

  const setCheckboxCheckedState = (dataItem, index) => {
    if (dataToSetCheckedState) {
      return dataToSetCheckedState[index];
    } else {
      return dataItem.checked === true ? true : false;
    }
  };

  return (
    <fieldset className={classNames(styles['checkbox-list'], className)}>
      {data.map((item, index) => (
        <div className={styles['checkbox-list__item']} key={index}>
          <Checkbox
            checked={dataToSetCheckedState ? setCheckboxCheckedState(item, index) : false}
            disabled={item.disabled === true ? true : false}
            name={name}
            value={item.value}
            sizeSmall={sizeSmall}
            label={item.label}
            onChange={() => onChangeHandler({ name: name, value: item.value })}
          />
        </div>
      ))}
    </fieldset>
  );
};

export default CheckboxList;
