export const stringToUserFriendlyURL = (string: string): string =>
  string
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters, keeping only letters, numbers, and spaces
    .trim() // Trim leading and trailing spaces
    .replace(/\s+/g, '-'); // Replace spaces with hyphens

export function truncateString(input: string, maxLength: number = 100): string {
  // If the input string is shorter than the maximum length, return it as is
  if (input.length <= maxLength) {
    return input;
  }

  // Find the last space within the maxLength limit
  const truncated = input.slice(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  // If there's no space, just return the truncated string
  // Otherwise, return the string up to the last space
  return lastSpaceIndex !== -1 ? truncated.slice(0, lastSpaceIndex) + '...' : truncated + '...';
}
