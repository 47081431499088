import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import Label from '../../../../../components/texts/Label/Label';
import styles from './AccountRoleAddQuiz.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CompleteProfileTemplate from '../../../CompleteProfileTemplate/CompleteProfileTemplate';
import GroupWithGap from '../../../../../components/lists/GroupWithGap/GroupWithGap';
import api from '../../../../../api';
import { useAppSelector } from '../../../../../redux/hooks';
import { AxiosError } from 'axios';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import { ApiResponseDTO } from '../../../../../dto/api';
import { ReactComponent as PersonIcon } from '../../../../../asset/images/icons/person_add.svg';
import RoutesEnum from '../../../../../enums/routes';
import TitleWithIcon from '../../../../../components/titles/TitleWithIcon/TitleWithIcon';
import CheckboxTiledList from '../../../../../components/inputs/CheckboxTiledList/CheckboxTiledList';

interface AccountRoleAddProps {}

const AccountRoleAddQuiz: FunctionComponent<AccountRoleAddProps> = () => {
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.user?.userId as string);

  const [roleInfo, setRoleInfo] = useState<Array<any>>();

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Onboarding.getSteps(userId);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          setRoleInfo(responseData.data);
          console.log('SCHEMA: ', responseData.data);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }

        callToast({
          title: 'Error',
          children: <>Something went wrong. Please try again later</>,
          variation: 'error',
        });

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  return (
    <div className={styles['role']}>
      <div className={styles['title-container']}>
        <TitleWithIcon
          className={styles['title']}
          icon={<PersonIcon style={{ fill: 'var(--color-main)', width: '32px' }} />}
        >
          <h3 className={'text text--h3'}>Add role</h3>
        </TitleWithIcon>
      </div>

      <main className={styles['main']}>
        <CompleteProfileTemplate
          formSchemaProp={roleInfo}
          // outputBySteps={false}
          // ref={childRef}
          // hideFooter={true}
          // hideHeader={true}
          hideSidebar={true}
          hideTitle={true}
        />
      </main>
    </div>
  );
};

export default AccountRoleAddQuiz;
