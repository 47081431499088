import { UserRoleEnum } from "../enums/user";

export const UserRolePlural: Record<UserRoleEnum, string> = {
  [UserRoleEnum.founder]: "Founders",
  [UserRoleEnum.incubator]: "Incubators",
  [UserRoleEnum['business-owner']]: "Business Owners",
  [UserRoleEnum.accelerator]: "Accelerators",
  [UserRoleEnum.investor]: "Investors",
  [UserRoleEnum['venture-studio']]: "Venture Studios",
  [UserRoleEnum.freelancer]: "Freelancers",
  [UserRoleEnum['coworking-space']]: "Coworking Spaces",
  [UserRoleEnum.consultant]: "Consultants",
  [UserRoleEnum.employee]: "Employees",
  [UserRoleEnum.agency]: "Agencies",
  [UserRoleEnum.government]: "Governments"
};
